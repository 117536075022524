<template>
    <div class="quiz-saya h-100 container-fluid" id="project">
        <div class="my-collections text-left">
            <div class="container-fluid my-3">
                    <div class="row px-2 d-flex justify-content-between align-items-center" :class="{'header__projects pb-4' : !$route.query.folder_name}">
                        <div class="col-md-5" :class="{'d-flex align-items-center justify-content-start' : $route.query.folder_name, 'ml-2' : !$route.query.folder_name}">
                            <button class="btn text-white" @click="$router.go(-1)" v-if="$route.query.folder_name">
                                <font-awesome-icon icon="fa-solid fa-chevron-left " />
                            </button>
                            <h3 class="text-white" :class="{'mb-2': !$route.query.folder_name}">{{ $route.query.folder_name ? $route.query.folder_name : 'Project'  }}</h3>
                            <h5 class="text-white font-weight-normal" v-if="!$route.query.folder_name">{{ $t('projects--p') }}</h5>
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-end create-new" v-if="!$route.query.folder_name">
                            <div class="dropdown mr-2">
                                <button class="btn btn-purple-nb d-flex align-items-center" data-toggle="dropdown" aria-expanded="false">
                                    <img src="@/assets/images/plus-white.svg" alt="Create new">
                                    <span class="text-white ml-2">{{ $t('create-new') }}</span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right mt-2">
                                    <div class="px-4 mt-2">
                                        <span>{{ $t('start-with--p') }}</span>
                                        <div class="items-create mt-2" @click="$router.push({ name : 'Generate-Videos', params : { mode : 'new', id : '1' } })">
                                            <p class="m-0 cursor-pointer">Voice Overs Video</p>
                                        </div>
                                    </div>
                                    <hr style="background:#2D2D2D;">
                                    <div class="px-4 mb-2">
                                        <span>{{ $t('or-start') }}</span>
                                        <div v-for="(data, index) in ai_generator" :key="index" class="items-create mt-2 cursor-pointer" @click="handleClickCN(data.to, data.params)">
                                            <p class="mb-0 cusor-pointer">{{ data.title }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row header__projects px-2 pb-3 d-flex justify-content-end align-items-center" v-if="$route.query.folder_name">
                        <div class="col-3 d-flex align-items-center justify-content-end create-new">
                            <div class="dropdown mr-2">
                                <button class="btn btn-purple-nb d-flex align-items-center" data-toggle="dropdown" aria-expanded="false">
                                    <img src="@/assets/images/plus-white.svg" alt="Create new">
                                    <span class="text-white ml-2">{{ $t('create-new') }}</span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right mt-2">
                                    <div class="px-4 mt-2">
                                        <span>{{ $t('start-with--p') }}</span>
                                        <div class="items-create mt-2" @click="$router.push({ name : 'Generate-Videos', params : { mode : 'new', id : '1' } })">
                                            <p class="m-0 cursor-pointer">Voice Overs Video</p>
                                        </div>
                                    </div>
                                    <hr style="background:#2D2D2D;">
                                    <div class="px-4 mb-2">
                                        <span>{{ $t('or-start') }}</span>
                                        <div v-for="(data, index) in ai_generator" :key="index" class="items-create mt-2 cursor-pointer" @click="handleClickCN(data.to, data.params)">
                                            <p class="mb-0 cusor-pointer">{{ data.title }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="px-3">
                <section id="folder" v-if="!$route.query.folder_name">
                    <Folder :dataFolder="list_folder" :active-index="activeFolderIndex" @click:active-folder="handleActiveFolder"/>
                </section>
            </div>
        </div>

        <div class="container-fluid">
                <div class="row align-items-center mt-3 px-2">
                    <div class="col-6 collections-tab">
                        <ul class="nav nav-tabs w-100 d-flex justify-content-start" id="myTab" role="tablist" v-if="!$route.query.folder_name">
                            <li class="nav-item" role="presentation">
                                <button @click="activeTab = 'recents'" :disabled="loading_quiz || loading_audio || loading_news_reader || loading_autodubb" class="nav-link active" id="quiz-tab" data-toggle="tab" data-target="#recent" type="button" role="tab" aria-controls="quiz" aria-selected="true"><h5 class="m-0 font-weight-normal">{{ $t('recents') }}</h5></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button @click="activeTab = 'favorites'" :disabled="loading_quiz || loading_audio || loading_news_reader || loading_autodubb" class="nav-link" id="favorites-tab" data-toggle="tab" data-target="#favorites" type="button" role="tab" aria-controls="favorites" aria-selected="false"><h5 class="m-0 font-weight-normal">{{ $t('favorites') }}</h5></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <!-- <button :disabled="loading_quiz || loading_audio" class="nav-link" id="drafts-tab" data-toggle="tab" data-target="#drafts" type="button" role="tab" aria-controls="drafts" aria-selected="false"><h5 class="m-0 font-weight-normal d-flex align-items-center">Drafts <div v-if="totalDrafts > 0" class="drafts-length ml-2"><span style="font-size:10px;" class="text-white">{{ totalDrafts }}</span></div></h5></button> -->
                                <button @click="activeTab = 'draft'" :disabled="loading_quiz || loading_audio || loading_news_reader || loading_autodubb" class="nav-link" id="drafts-tab" data-toggle="tab" data-target="#drafts" type="button" role="tab" aria-controls="drafts" aria-selected="false"><h5 class="m-0 font-weight-normal d-flex align-items-center">{{ $t('drafts') }}</h5></button>
                            </li>
                        </ul>
                        <h5 class="text-white mb-2 text-left ml-3" v-else>{{ $t('content') }} <span v-if="$route.query.folder_name == 'Dubbs' && totalQueueAutodub > 0">| <span class="processing--dubs"> {{ totalQueueAutodub }}+ videos processing now</span></span></h5>
                    </div>
                    <div class="col-6 d-flex align-items-center justify-content-end">
                        <button v-if="$route.query.folder_name != 'Dubbs'" class="btn d-flex align-items-center p-0 mr-2" @click="changeDisplay('List')">
                            <TabsListIcons :fill="activeDisplay == 'List' ? '#585859' : '#2D2D2D'"/>
                        </button>
                        <button class="btn d-flex align-items-center p-0 mr-2" @click="changeDisplay('Galery')">
                            <TabsGaleryIcons :fill="activeDisplay == 'Galery' ? '#585859' : '#2D2D2D'"/>
                        </button>
                    </div>
                </div>

                <section>
                    <Tabs
                        :activeDisplay="activeDisplay"
                        :activeTab="activeTab"
                        :contentRecent="contentRecent"
                        :contentFav="quizFavorites"
                        :contentDrafts="contentDrafts"
                        :contentNewsReader="newsReaderCreator"
                        @click:duplicate-quiz="duplicate"
                        @click:delete="modalDelete"
                        @click:edit-quiz="editQuizSaya"
                        @click:share-quiz="share"
                        @click:edit-audio="editAudio"
                        @click:edit-news-reader="handleEditNewsReader"
                        @click:handle-rename="handleRename"
                        @click:to-library-qgen="handleToLibraryQgen"
                        @click:preview-auto-dubb="handlePreviewAutoDubb"
                    />
                </section>
            </div>

        <!-- CONFIRM DELETE MODAL -->
        <b-modal id="delete-konten" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('delete-konten')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Delete Content">
                <div class="mt-3">
                    <h5 class="font-weight-bold" v-html="$t('confirm-delete-content')"></h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="$bvModal.hide('delete-konten')"><p class="mb-0 font-weight-bold">{{ $t('cancel') }}</p></button>
                    <button class="btn btn-primary btn-purple-nb" @click="deleteQuiz(type_content)"><p class="mx-2 mb-0 font-weight-bold">{{ $t('yes') }}</p></button>
                </div>
            </div>
        </b-modal>
        <RenameVoiceModelVue content v-model="rename" @cancel-rename="handleCancelAction" @save-name="handleSaveName"/>
        <ServiceTTVMaintenance />
        <Soca v-if="content_recent_load || loading_duplicate || loading_quiz || loading_audio || loading_news_reader || loading_autodubb"/>
        <ADPreviewModal id="show-preview-modal" :name="previewAutoDubName" :job-id="jobIdAutodub" :src="previewAutoDub" :is-feedback="isFeedback" @click:feedback-autodub="$bvModal.hide('show-preview-modal'), $bvModal.show('feedback-autodub-modal')" :content-id="idAutodubActive" feedback/>
        <ADFeedbackModal id="feedback-autodub-modal" @click:submit-feedback="handleFeedbackAutodub" :is-submit="isSubmitFeedback"/>
    </div>
</template>
<script>
import { getQuizCreatorAll, getQuizFavorite, deleteQuizCreator, postContent, getQuizCreatorDraft, creatorUserUnrating, getContentRecentAll, getDataProjectFolderAll } from '@/services/quiz-creator/quiz-creator.service'
import { getTotalDrafts, getAudioDrafts } from '@/services/drafts/drafts.service'
import { getAudio } from '@/services/audio/audio.service'
import { deleteContents } from '@/services/contents/contents.service'
import { templateUserUnrating } from '@/services/quiz/quiz.service'
import { getVideo, getDraftVideo } from '@/services/video/video.service'
import { mapActions } from "vuex";
import { AlertUtils } from "@/mixins/AlertUtils";
import { base_url_home } from "../../../config/base_url";
import { updateContent } from "@/services/contents/contents.service";
import { checkStatusApi } from '@/services/third_party/third_party.service'
import { base_url_qgen, base_url_auto_dubbing } from "@/config/base_url"
import { getDataNewsReader } from "@/services/news-reader/news-reader.service"
import { saveToProjectNewsReader, createNewsReader } from '@/services/news-reader/news-reader.service'
import { getAutodubList, postFeedbackAutodub, renameAutoDub, deleteAutoDub, getQueueAutodub, autodubbCheckAccess } from '@/services/auto-dub/autodub.service'

import RenameVoiceModelVue from '@/components/modal/RenameVoiceModel.vue'
import ServiceTTVMaintenance from '@/components/modal/ServiceTTVMaintenance';
import Folder from '@/components/projects/Folder';
import Soca from '@/components/loading/Soca'
import ADPreviewModal from "@/components/auto-dubbing/modal/PreviewAutoDub.vue"
import ADFeedbackModal from "@/components/auto-dubbing/modal/Feedback.vue"

// import Tabs from "./Tabs/index.vue";
import Tabs from './Tabs/index';

// ICONS
import TabsGaleryIcons from '@/components/icons/creator/TabsGaleryIcons.vue';
import TabsListIcons from '@/components/icons/creator/TabsListIcons.vue';

import $ from 'jquery';


import moment from 'moment';
export default {
    mixins: [AlertUtils],
    components: {
        RenameVoiceModelVue,
        ServiceTTVMaintenance,
        TabsGaleryIcons,
        TabsListIcons,
        Tabs,
        Folder,
        Soca,
        ADPreviewModal,
        ADFeedbackModal
    },

    data(){
        return {
            loading_duplicate: false,
            quizCreator : [],
            contentRecent : [],
            contentDrafts : [],
            audioCreator : [],
            videoCreator : [],
            newsReaderCreator : [],
            users : [],
            questionCreator : [],
            quizFavorites : [],
            draftsAudio: [],
            draftVideo:[],
            draftsQuiz: ["awd"],
            activeDisplay: 'List',
            list_file: [],
            list_folder:[
                {
                    folder_name: 'Videos',
                    total_data: 0,
                    tipe_data: 'Files',
                    date_update: '',
                    image_thumbnail: []
                },
                {
                    folder_name: 'Readers',
                    total_data: 0,
                    tipe_data: 'Articles',
                    date_update: '',
                    image_thumbnail: []
                },
                {
                    folder_name: 'Dubbs',
                    total_data: 0,
                    tipe_data: 'Files',
                    date_update: '',
                    image_thumbnail: []
                },
                {
                    folder_name: 'Q-gen',
                    total_data: 0,
                    tipe_data: 'Quizzes',
                    date_update: '',
                    image_thumbnail: []
                },
            ],

            totalDrafts : null,
            filter: null,
            quiz_id_delete : null,
            type_content : null,
            unrating_author : null,
            activeFolderIndex: null,

            id_rename: null,
            rename: null,

            intervalContent : null,

            show_create_content : false,
            type_rename: false,
            loading : false,

            loading_quiz : false,
            loading_audio : false,
            loading_video : false,
            loading_news_reader: false,
            loading_autodubb : false,

            linkShare : '',
            useCaseTrendingQuiz : [
                {
                    title : 'Sistem Pemasaran',
                    image : 'Pemasaran & Penjualan.svg',
                },
                {
                    title : 'Simple Present',
                    image : 'Bahasa Inggris.svg',
                },
                {
                    title : 'Bahasa Indonesia',
                    image : 'Bahasa Indonesia.svg',
                },
                {
                    title : 'Ekonomi Dagang',
                    image : 'ekonomi.svg',
                },
                {
                    title : 'Desain Seni Budaya',
                    image : 'seni-budaya.svg',
                },
            ],

            ai_generator : [
                {
                    icon : 'ic_bulat.svg',
                    title : 'AI Voice Clone',
                    to: 'Personal-Voice'
                },
                {
                    icon : 'ic_segienam.svg',
                    title : 'AI News Reader',
                    to : 'NewsReader',
                },
                {
                    icon : 'ic_segienam.svg',
                    title : 'AI Dubbing',
                    to : 'AutoDubbing',
                },
            ],

            statusApi: [],
            content_recent_load: false,
            folder_load: false,
            activeTab: 'recents',

            previewAutoDub: null,
            idAutodubActive: null,
            jobIdAutodub: null,
            previewAutoDubName: null,
            isFeedback: false,
            isSubmitFeedback: false,

            totalQueueAutodub: 0,
        }
    },
    mounted(){
        $('.main-container-fluid').addClass('px-0');
        $("div.quiz-saya").parent().addClass('pb-0');

        if(localStorage.activeDisplayProject){
            this.activeDisplay = localStorage.activeDisplayProject;
        }
        this.loading_video = true;
        // this.getDataProjectFolder()
        // this.getContentRecent()
        // this.getQuizCreator()


        // this.getTotalDrafts()
        // this.getAudio()
        // this.getDraftVideo()
        // this.getVideo();
        this.checkStatusApi()

        if(!this.$route.query.folder_name){
            this.getDataProjectFolder()
            this.getContentRecent()
            this.getQuizFavorite()
            this.getAudioDrafts()
            this.getQuizDraft()
        }
        if(this.$route.query.folder_name == 'Videos'){
            this.activeTab = 'open_file'
            this.activeDisplay = 'Galery'
            this.getAudio();
        }else if(this.$route.query.folder_name == 'Q-gen'){
            this.activeTab = 'open_file'
            this.activeDisplay = 'Galery'
            this.getQuizCreator();
        } else if(this.$route.query.folder_name == 'Readers') {
            this.activeTab = 'open_file'
            this.activeDisplay = 'Galery'
            this.getNewsReader();
        } else if(this.$route.query.folder_name == 'Dubbs') {
            this.activeTab = 'open_file'
            this.activeDisplay = 'Galery'
            this.loading_autodubb = true
            this.getAutoDubb();
            this.getQueueAutodub()
            this.intervalContent = setInterval(() => {
                this.getAutoDubb();
                this.getQueueAutodub()
            }, 5000);
        }

        // this.intervalContent = setInterval(() => {
        //     this.getVideo();
        // }, 5000);
        let users = localStorage.getItem("users")
        this.users =JSON.parse(users)
    },

    computed: {
        searchMyCollections() {
            if (this.filter) {
                return this.quizCreator.filter((item) => {
                return this.filter
                    .toLowerCase()
                    .split(" ")
                    .every(
                    (v) =>
                        item.quiz_name.toLowerCase().includes(v)
                    );
                });
            } else {
                return this.quizCreator;
            }
        },

        searchMyFavorite() {
            if(this.quizFavorites != null && this.quizFavorites.length > 0) {
                if (this.filter) {
                    return this.quizFavorites.filter((item) => {
                    return this.filter
                        .toLowerCase()
                        .split(" ")
                        .every(
                        (v) =>
                            item.quiz_name.toLowerCase().includes(v)
                        );
                    });
                } else {
                    return this.quizFavorites;
                }
            } else {
                return null;
            }
        },
    },

    methods: {
        ...mapActions(["setAuthor"]),

        changeDisplay(val){
            this.activeDisplay = val

            localStorage.setItem('activeDisplayProject',val);
        },

        async getQuizCreator(){
            this.loading_quiz = true;
            this.contentRecent = [];
            await getQuizCreatorAll().then((response)=>{
                if(response.status){
                    this.contentRecent = response.data;
                    this.loading_quiz = false;
                } else {
                    this.contentRecent = [];
                    this.loading_quiz = false;
                }
            }).catch((err)=>{
                this.$vs.loading.close();
                this.loading_quiz = false;
                console.log("data err", err)
            })
        },

        async getNewsReader() {
            this.loading_news_reader = true
            this.contentRecent = [];
            await getDataNewsReader()
            .then((res) => {
                if(res.status) {
                    this.contentRecent = res.data
                }
                this.loading_news_reader = false;
            })
            .catch((err)=>{
                this.loading_news_reader = false;
                console.log("data err", err)
            })
        },

        getAutoDubb(shouldShowPreview) {
            getAutodubList()
            .then((response) => {
                if(response.status) {
                    this.contentRecent = response.data;
                    if(shouldShowPreview) {
                        this.isSubmitFeedback = false
                        this.$bvModal.hide('feedback-autodub-modal')
                        this.showPreviewAfterFeedback()
                    }
                }
                this.loading_autodubb = false
            })
            .catch((error) => {
                this.loading_autodubb = false
                console.error(error)
            })
        },

        async getDataProjectFolder(){
            this.folder_load = true;
            await getDataProjectFolderAll().then((response)=>{
                if(response.status){

                    this.list_folder[0].total_data = response.data.video_data.total_data;
                    this.list_folder[0].image_thumbnail = response.data.video_data.image_thumbnail;
                    this.list_folder[0].date_update = response.data.video_data.date_update;

                    this.list_folder[1].total_data = response.data.readers_data.total_data;
                    this.list_folder[1].image_thumbnail = response.data.readers_data.text_thumbnail;
                    this.list_folder[1].date_update = response.data.readers_data.date_update;

                    this.list_folder[2].total_data = response.data.dubbs_data.total_data;
                    this.list_folder[2].image_thumbnail = response.data.dubbs_data.image_thumbnail;
                    this.list_folder[2].date_update = response.data.dubbs_data.date_update;

                    this.list_folder[3].total_data = response.data?.q_gen_data?.total_data;
                    this.list_folder[3].image_thumbnail = response.data.q_gen_data.image_thumbnail;
                    this.list_folder[3].date_update = response.data.q_gen_data.date_update;
                    this.folder_load = false;
                    // this.list_folder.splice(2, 1)
                } else {
                    // this.contentRecent = [];
                    this.folder_load = false;
                }
            }).catch((err)=>{
                this.folder_load = false;
                console.log("data err", err)
            })
        },
        async getContentRecent(){
            this.content_recent_load = true;
            await getContentRecentAll().then((response)=>{
                if(response.status){
                    this.contentRecent = response.data;
                    this.content_recent_load = false;
                    if(this.isSubmitFeedback) {
                        this.isSubmitFeedback = false
                        this.$bvModal.hide('feedback-autodub-modal')
                        this.showPreviewAfterFeedback()
                    }
                } else {
                    this.contentRecent = [];
                    this.content_recent_load = false;
                }
            }).catch((err)=>{
                this.content_recent_load = false;
                console.log("data err", err)
            })
        },
        async getQuizDraft(){
            this.loading = true;
            await getQuizCreatorDraft().then((response)=>{
                if(response.status){
                    this.draftsQuiz = response.data;
                    this.contentDrafts = this.contentDrafts.concat(this.draftsQuiz)
                    this.loading = false;
                } else {
                    this.draftsQuiz = [];
                    this.loading = false;
                }
            }).catch((err)=>{
                this.$vs.loading.close();
                this.loading = false;
                console.log("data err", err)
            })
        },
        editQuizSaya(id, mode){
            let token = null

            if(localStorage.getItem("users")) {
                var users = JSON.parse(localStorage.getItem("users"))
                if(users.token) {
                    token = users.token
                } else {
                    token = localStorage.getItem("token_client") ? localStorage.getItem("token_client") : null
                }
            } else if (localStorage.getItem("token_client")) {
                token = localStorage.getItem("token_client")
            }
            // this.$router.push({ name : 'Generative-Ai', params: { mode: mode, id: id } })
            window.open(base_url_qgen + 'login?token=' + token + '&request=q-gen&mode=' + mode + "&id=" + id);
        },
        setToLocal(data, id){
            localStorage.removeItem("quiz_name_edit")
            localStorage.removeItem("description_edit")
            localStorage.removeItem("images_cover_edit")
            localStorage.removeItem("category_id_edit")
            localStorage.removeItem("grade_id_edit")
            localStorage.removeItem("sub_grade_id_edit")
            localStorage.removeItem("level_edit")

            localStorage.setItem('listQuestionCreator', JSON.stringify(data))
            this.$router.push({ name: "Edit-My-Quiz", query: {key:id} });
        },

        getDate(item){
            const date = moment(item);
            let now = moment();
            now = now.add(420, 'minutes')
            const diff = now.diff(date, 'seconds');

            if(diff < 1){
                return 'a few second ago';
            }else if (diff < 60) {
                return diff + ' seconds ago';
            } else if (diff < 3600) {
                const minutes = Math.floor(diff / 60);
                return minutes + ' minutes ago';
            } else if (diff < 86400) {
                const hours = Math.floor(diff / 3600);
                return hours + ' hours ago';
            } else if (diff < 604800) {
                const days = Math.floor(diff / 86400);
                return days + ' days ago';
            } else if (diff < 2592000) {
                const weeks = Math.floor(diff / 604800);
                return weeks + ' weeks ago';
            } else if (diff < 31536000) {
                const months = Math.floor(diff / 2592000);
                return months + ' months ago';
            } else {
                const years = Math.floor(diff / 31536000);
                return years + ' years ago';
            }
        },

        detailQuiz(id, author, name) {
            localStorage.removeItem("quiz_name_temp")
            localStorage.removeItem("description_temp")
            localStorage.removeItem("images_cover_temp")
            localStorage.removeItem("category_id_temp")
            localStorage.removeItem("grade_temp")
            localStorage.removeItem("sub_grade_temp")
            localStorage.removeItem("index_grade_temp")
            console.log(name)

            this.setAuthor(author)
            this.$router.push({
                name: "Detail-Library",
                query : { id : id, author : author}
                // params: { id : id, name : name.replace(/ /g, '-') },
            });
        },
        share(id,author){
            let vm = this
            vm.linkShare = base_url_home + 'products/library/detail-quiz?id=' + id + '&author=' +  author

            vm.$copyText(vm.linkShare).then(function () {
                vm.alertSuccess('Link successfully copied')
            }, function () {
                vm.alertFail('Link failed to copy')
            })
        },

        getMinQuiz(length){

            let time = length * 30

            let min = Math.floor(time / 60);

            return min
        },

        async getQuizFavorite(){
            await getQuizFavorite()
            .then((response) => {
                if (response.status && response.status == true){
                    if(response.data != null){
                        this.quizFavorites = response.data
                    }
                }
            })
        },

        cutName(item){
            if(item != undefined){
                const name = item.split(" ")

                return name[0]
            } else {
                return "-"
            }
        },

        modalDelete(id, type, author){
            if(id != null){
                this.quiz_id_delete = null;
            }

            if(author != undefined){
                this.unrating_author = author
            }

            this.type_content = type
            this.quiz_id_delete = id;
            this.$bvModal.show('delete-konten')
        },

        deleteQuiz(type){
            this.contentDrafts = [];
            if(type == 'quiz'){
                deleteQuizCreator(this.quiz_id_delete)
                .then((response) => {
                    if (response.status && response.status == true){
                        this.quiz_id_delete = null;
                        this.type_content = null;

                        if(this.$route.query.folder_name){
                            this.getQuizCreator()
                        }else{
                            this.getQuizDraft();
                            this.getAudioDrafts();
                            this.getDataProjectFolder()
                            this.getContentRecent()
                            this.getNewsReader()
                        }


                        //Syntax agar setelah berhasil get data (jika posisi tabs sedang delete drafts tabs masih di draft namun yang dibuka tabs content)
                        // let contentsTab = document.getElementById('quiz-tab');
                        // let favTab = document.getElementById('favorites-tab');
                        // let draftsTab = document.getElementById('drafts-tab');

                        // contentsTab.classList.add('active')
                        // favTab.classList.remove('active')
                        // draftsTab.classList.remove('active')

                        this.$bvModal.hide('delete-konten')
                        this.alertSuccess('Content has been deleted !')
                    } else {
                        this.quiz_id_delete = null;
                        this.type_content = null;
                        this.unrating_author = null;
                        this.$bvModal.hide('delete-konten')
                        this.alertFail('Your content failed to delete !')
                    }
                }).catch((err)=>{
                    this.quiz_id_delete = null;
                    this.type_content = null;
                    this.unrating_author = null;
                    this.$bvModal.hide('delete-konten')
                    this.alertFail('Your content failed to delete !')
                    console.log("data err", err)
                })
            } else if (type == 'audio') {
                deleteContents(this.quiz_id_delete)
                .then((response) =>{
                    if(response.status == true) {
                        this.quiz_id_delete = null;
                        this.type_content = null;
                        this.unrating_author = null;

                        if(!this.$route.query.folder_name){
                            this.getAudioDrafts()
                            this.getQuizDraft()
                            this.getDataProjectFolder()
                            this.getContentRecent()
                        } else {
                            this.getAudio()
                        }

                        this.$bvModal.hide('delete-konten')
                        this.alertSuccess('Content has been deleted !')
                    } else {
                        this.quiz_id_delete = null;
                        this.type_content = null;
                        this.unrating_author = null;
                        this.$bvModal.hide('delete-konten')
                        this.alertFail('Your content failed to delete !')
                    }
                }).catch((err)=>{
                    this.quiz_id_delete = null;
                    this.type_content = null;
                    this.unrating_author = null;
                    this.$bvModal.hide('delete-konten')
                    this.alertFail('Your content failed to delete !')
                    console.log("data err", err)
                })
            } else if (type == 'unrating') {
                if(this.unrating_author == 0){
                    templateUserUnrating(this.quiz_id_delete)
                    .then((response) => {
                        if(response.status && response.status == true){
                            this.quiz_id_delete = null;
                            this.type_content = null;
                            this.unrating_author = null;

                            if(!this.$route.query.folder_name){
                                this.getQuizFavorite()
                            }


                            this.$bvModal.hide('delete-konten')
                            this.alertSuccess('Content has been deleted !')
                        } else {
                            this.quiz_id_delete = null;
                            this.type_content = null;
                            this.unrating_author = null;
                            this.$bvModal.hide('delete-konten')
                            this.alertFail('Your content failed to delete !')
                        }
                    }).catch((err)=>{
                        this.quiz_id_delete = null;
                        this.type_content = null;
                        this.unrating_author = null;
                        this.$bvModal.hide('delete-konten')
                        this.alertFail('Your content failed to delete !')
                        console.log("data err", err)
                    })
                } else {
                    creatorUserUnrating(this.quiz_id_delete)
                    .then((response) => {
                        if(response.status && response.status == true){
                            this.quiz_id_delete = null;
                            this.type_content = null;
                            this.unrating_author = null;
                            if(!this.$route.query.folder_name){
                                this.getQuizFavorite()
                            }
                            this.$bvModal.hide('delete-konten')
                            this.alertSuccess('Content has been deleted !')
                        } else {
                            this.quiz_id_delete = null;
                            this.type_content = null;
                            this.unrating_author = null;
                            this.$bvModal.hide('delete-konten')
                            this.alertFail('Your content failed to delete !')
                        }
                    }).catch((err)=>{
                        this.quiz_id_delete = null;
                        this.type_content = null;
                        this.unrating_author = null;
                        this.$bvModal.hide('delete-konten')
                        this.alertFail('Your content failed to delete !')
                        console.log("data err", err)
                    })
                }
            } else if (type == 'news_reader') {
                let formData = {
                    deleted: true
                }

                saveToProjectNewsReader(formData, this.quiz_id_delete)
                .then((response) => {
                    if(response.status) {
                        if(this.$route.query.folder_name) {
                            this.getDataProjectFolder()
                            this.getNewsReader();
                        } else {
                            this.getDataProjectFolder()
                            this.getContentRecent()
                        }
                        this.alertSuccess('Changes saved successfully')
                        this.$bvModal.hide('delete-konten')
                    }
                })
            } else if (type == 'auto-dubb') {
                deleteAutoDub(this.quiz_id_delete)
                .then((response) =>{
                    if(response.status == true) {
                        this.quiz_id_delete = null;
                        this.type_content = null;
                        this.unrating_author = null;

                        if(!this.$route.query.folder_name){
                            this.getDataProjectFolder()
                            this.getContentRecent()
                        } else {
                            this.loading_autodubb = true
                            this.getAutoDubb()
                        }

                        this.$bvModal.hide('delete-konten')
                        this.alertSuccess('Content has been deleted !')
                    } else {
                        this.quiz_id_delete = null;
                        this.type_content = null;
                        this.unrating_author = null;
                        this.$bvModal.hide('delete-konten')
                        this.alertFail('Your content failed to delete !')
                    }
                }).catch((err)=>{
                    this.quiz_id_delete = null;
                    this.type_content = null;
                    this.unrating_author = null;
                    this.$bvModal.hide('delete-konten')
                    this.alertFail('Your content failed to delete !')
                    console.log("data err", err)
                })
            }
        },

        addQuiz(tipe){
            if(this.users){
                localStorage.removeItem("listQuestionCreator");
                localStorage.removeItem("newListQuestionCreator")
                localStorage.removeItem("questionNew")
                localStorage.removeItem("listQuestion")
                localStorage.removeItem("listQuestionCreate")
                localStorage.removeItem("listQuestionCreatePolling")
                localStorage.removeItem("backUpPollingQuestion")
                localStorage.removeItem("backUpCreateQuestion")
                localStorage.removeItem("backUpUpdateQuestion")
                localStorage.removeItem("quiz_name")
                localStorage.removeItem("quiz_description")
                localStorage.removeItem("images_cover")
                localStorage.removeItem("category_id")
                localStorage.removeItem("createTag")
                localStorage.removeItem("quiz_name_polling")
                localStorage.removeItem("quiz_description_polling")
                localStorage.removeItem("images_cover_polling")
                localStorage.removeItem("category_id_polling")
                localStorage.removeItem("materiAi")
                localStorage.removeItem("gradeAi")
                localStorage.removeItem('lengthQuestionAi')
                localStorage.removeItem("subGradeAi")
                localStorage.removeItem("descriptionAi")
                localStorage.removeItem("grade")
                localStorage.removeItem("sub_grade")
                localStorage.removeItem("index_grade")

                this.$store.state.dataQuiz.category_id = null,
                this.$store.state.dataQuiz.quiz_name = null,
                this.$store.state.dataQuiz.description = null,
                this.$store.state.dataQuiz.images_cover = null

                let formData = {
                    content_type : tipe
                };

                postContent(formData)
                .then((response) =>{
                    if(response.status && response.status == true){
                        if(tipe == 'quiz'){
                            this.$router.push({ name: 'Create-My-Quiz'})
                        } else if (tipe == 'polling'){
                            this.$router.push({ name: 'Setup-Polling'})
                        } else if(tipe == 'generative-ai') {
                            // if(this.users.subscribe == true) {
                                this.$router.push({ name: 'Generative-Ai'})
                            // }
                        }
                    }
                })
            } else {
                this.$bvModal.show('signIn')
            }
        },

        duplicate(id, author){
            let token = null

            if(localStorage.getItem("users")) {
                var users = JSON.parse(localStorage.getItem("users"))
                if(users.token) {
                    token = users.token
                } else {
                    token = localStorage.getItem("token_client") ? localStorage.getItem("token_client") : null
                }
            } else if (localStorage.getItem("token_client")) {
                token = localStorage.getItem("token_client")
            }
            // this.$router.push({ name : 'Generative-Ai', params: { mode: mode, id: id } })
            window.open(base_url_qgen + 'login?token=' + token + '&request=q-gen&mode=new' + "&id=" + id + "&author=" + author + "&is-duplicate=true");
        },

        // Get Total Draft
        async getTotalDrafts(){
            await getTotalDrafts()
            .then((response) =>{
                if(response.status == true){
                    this.totalDrafts = response.data
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            }).catch((err)=>{
                this.$vs.loading.close();
                console.log("data err", err)
            })
        },

        // Get Audio Drafts
        async getAudioDrafts(){
            await getAudioDrafts()
            .then((response) =>{
                if(response.status == true){
                    this.draftsAudio = response.data
                    this.contentDrafts = this.contentDrafts.concat(this.draftsAudio)
                    this.$vs.loading.close();
                } else {
                    this.draftsAudio = []
                    this.$vs.loading.close();
                }
            }).catch((err)=>{
                this.$vs.loading.close();
                console.log("data err", err)
            })
        },

        // Get Audio Collections
        async getAudio(){
            this.loading_audio = true;
            this.contentRecent = [];
            await getAudio()
            .then((response)=>{
                if(response.status == true){
                    this.contentRecent = response.data
                    this.loading_audio = false;
                } else {
                    this.contentRecent = []
                    this.loading_audio = false;
                }
            }).catch((err)=>{
                console.log("data err", err)
            })
        },

        // Edit audio by mode
        editAudio(id, mode){
            this.$router.push({ name : 'Generate-Videos', params : { mode : mode, id : id } })
        },

        toTtq(){
            localStorage.removeItem('listQuestionCreate')
            localStorage.removeItem('materiAi')
            localStorage.removeItem('subGradeAi')
            localStorage.removeItem('gradeAi')
            localStorage.removeItem('lengthQuestionAi')
            this.$router.push({ name: 'Generative-Ai', params : { mode: 'new', id : '1'}})
        },

        deleteContent(id){
            deleteContents(id)
            .then((response) =>{
                if(response.status == true) {
                    console.log(response)
                }
            })
        },

        async getVideo(){
            await getVideo()
            .then((response)=>{
                if(response.status == true){
                    this.videoCreator = response.data
                    this.$vs.loading.close();
                    this.loading_video = false;
                } else {
                    this.videoCreator = []
                    this.$vs.loading.close();
                    this.loading_video = false;
                }
            }).catch((err)=>{
                this.$vs.loading.close();
                console.log("data err", err)
            })
        },

        async getDraftVideo(){
            await getDraftVideo()
            .then((response) => {
                if(response.status) {
                    this.draftVideo = response.data
                } else {
                    this.draftVideo = []
                }
            })
        },

        handleClickVideo(id, name, isfinal, progress){
            if(isfinal && progress == "100") {
                // to video page
                this.$router.push({ name : 'Preview-Audio-Content', params : { name : name.replace(/ /g, '-'), id : id }, query: { type : 'video' } })
            }
        },

        handleClickDraftVideo(id){
            if(this.statusApi[2]?.status == 'ok') {
                this.$router.push({ name : 'Generate-Videos', params : { mode : 'draft', id : id } })
            } else {
                this.$bvModal.show('sttvm-modal')
            }
        },

        handleClickCN(val, params) {
            if(val) {
                if(params) {
                    this.$router.push({ name : val, params : { mode : 'new', id : '1' } })
                } else if(val == 'NewsReader') {
                    this.toNewsReader()
                } else if (val == 'AutoDubbing') {
                    this.checkAccessAD()
                } else {
                    this.$router.push({ name : val })
                }
            }
        },
        handleRename(id, name, type) {
            this.id_rename = id
            this.rename = name
            this.type_rename = type
            this.$bvModal.show('rvm-modal')
        },
        handleCancelAction() {
            this.id_rename = null
            this.rename = null
            this.type_rename = null
        },
        async handleSaveName() {
            let formData = {
                content_name : this.rename
            }

            if(this.type_rename == 'nr') {
                await saveToProjectNewsReader(formData, this.id_rename)
                .then((response) => {
                    if(response.status) {
                        if(this.$route.query.folder_name) {
                            this.getDataProjectFolder()
                            this.getNewsReader();
                        } else {
                            this.getDataProjectFolder()
                            this.getContentRecent()
                            this.getAudio()
                            this.getVideo();
                            this.getNewsReader();
                        }
                        this.alertSuccess('Changes saved successfully')
                        this.$bvModal.hide('rvm-modal')
                    }
                })
            } else if (this.type_rename == 'ad') {
                await renameAutoDub(formData, this.id_rename)
                .then((response) => {
                    if(response.status) {
                        if (this.$route.query.folder_name == 'Dubbs') {
                            this.loading_autodubb = true
                            this.getDataProjectFolder()
                            this.getAutoDubb()
                        } else {
                            this.getDataProjectFolder()
                            this.getContentRecent()
                        }
                        this.alertSuccess('Changes saved successfully')
                        this.$bvModal.hide('rvm-modal')
                    }
                })
            } else {
                await updateContent(formData, this.id_rename)
                .then((response) => {
                    if(response.status) {
                        if(this.$route.query.folder_name == 'Videos') {
                            this.getDataProjectFolder()
                            this.getAudio()
                        } else if (this.$route.query.folder_name == 'Dubbs') {
                            this.getDataProjectFolder()
                            this.loading_autodubb = true
                            this.getAutoDubb()
                        } else {
                            this.getDataProjectFolder()
                            this.getContentRecent()
                            this.getVideo();
                            this.getNewsReader();
                        }
                        this.alertSuccess('Changes saved successfully')
                        this.$bvModal.hide('rvm-modal')
                    }
                })
            }
        },
        checkStatusApi() {
			checkStatusApi()
			.then((response) => {
				if(response.status == 200) {
					this.statusApi = response.data
				}
			})
		},

        handleEditNewsReader(id) {
            this.$router.push({ name : 'NewsReader', params : { mode: 'edit', id: id } })
        },

        handleActiveFolder(index) {
            if(index == this.activeFolderIndex) {
                let folder = this.list_folder[index].folder_name
                this.$router.push({ name : 'My-Quiz', query : { folder_name: folder }})
            }
            this.activeFolderIndex = index
        },
        toNewsReader() {
            createNewsReader()
            .then((response) => {
                if(response.status) {
                    this.$router.push({ name : 'NewsReader', params : { mode : 'create', id : response.data.content_id } })
                }
            })
        },
        handleToLibraryQgen(id, author) {
            window.open(base_url_qgen + 'products/library/detail-quiz?id=' + id + "&author=" + author)
        },
        handlePreviewAutoDubb(data) {
            if(data.content_file) {
                this.previewAutoDub = base_url_auto_dubbing + 'autodub/stream_obj?path=' + data.content_file
                this.isFeedback = data.feedback_status
                this.idAutodubActive = data._id
                this.jobIdAutodub = data.job_id
                this.previewAutoDubName = data.content_name
                if(this.jobIdAutodub && this.previewAutoDub) {
                    this.$bvModal.show('show-preview-modal')
                }
                // getStreamObj(data.content_file)
                // .then((response) => {
                //     console.log(response)
                // })
            }
        },
        handleFeedbackAutodub(val) {
            this.isSubmitFeedback = true
            let formData = {
                feedback_option : val.option,
                feedback_comment : val.comment
            }
            postFeedbackAutodub(formData, this.idAutodubActive)
            .then((response) => {
                if(response.status) {
                    this.loading_autodubb = true
                    if(this.$route.query.folder_name == 'Dubbs') {
                        this.getAutoDubb(this.isSubmitFeedback)
                    } else {
                        this.getDataProjectFolder()
                        this.getContentRecent()
                        this.loading_autodubb = false
                    }
                }
            })
            .catch(() => {
                this.isSubmitFeedback = false
                this.alertFail('Something went wrong!')
                this.$bvModal.hide('feedback-autodub-modal')
            })
        },
        showPreviewAfterFeedback() {
            let previousPreview = this.contentRecent.find((item) => item._id == this.idAutodubActive)
            this.handlePreviewAutoDubb(previousPreview)
        },
        getQueueAutodub() {
            getQueueAutodub()
            .then((response) => {
                if(response.status == 200) {
                    this.totalQueueAutodub = response.data
                }
            })
        },
        checkAccessAD() {
            this.isCheck = true
            autodubbCheckAccess()
            .then((response) => {
                if(response.status) {
                    this.$router.push({ name : 'AutoDubbing', params : { mode : 'new', id : '1' } })
                } else {
                    this.$bvModal.show('info-access-autodubb')
                }
                this.isCheck = false
            })
            .catch(() => {
                this.isCheck = false
            })
        }
    },

    beforeDestroy() {
        $("div.quiz-saya").parent().removeClass('pb-0');
        clearInterval(this.intervalContent);
    },
}
</script>
<style>
    .btn-primary:not(:disabled):not(.disabled):active{
        background-color: #6D75F6;
        border-color: #6D75F6;
    }
</style>
<style scoped>
#project .loading--fixed {
    position: fixed;
    left: 10%;
    top: 0;
}

/* START FOLDER STYLE */
.grid-handle{
    position: absolute;
    inset: 0px;
    padding: 16px 8px 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.grid__img-parent{
    overflow: hidden;
    width: 100%;
    height: 100%;
    gap: 4px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
}
.grid-options[data-items="1"] {
    grid-template-areas:
        "one one one one"
        "one one one one";
}
.grid-options[data-items="1"] {
    grid-template-areas:
        "one one two two"
        "one one two two";
}
.grid-options[data-items="3"] {
    grid-template-areas:
        "one one two two"
        "one one three three";
}
.grid-options[data-items="4"] {
    grid-template-areas:
        "one one two two"
        "one one three four";
}
.grid-options > .image__handle-grid:nth-child(1) {
    grid-area: one / one / one / one;
}
.grid-options > .image__handle-grid:nth-child(2) {
    grid-area: two / two / two / two;
}
.grid-options > .image__handle-grid:nth-child(3) {
    grid-area: three / three / three / three;
}
.grid-options > .image__handle-grid:nth-child(4) {
    grid-area: four / four / four / four;
}
.pr-folder__title-des{
    color: #fff;
    display: flex;
    box-sizing: border-box;
    min-width: initial;
    min-height: initial;
    max-width: none;
    max-height: none;
    z-index: auto;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: end;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
    overflow: hidden;
}
.folder__title-des{
    display: flex;
    box-sizing: border-box;
    min-width: initial;
    min-height: initial;
    max-width: none;
    max-height: none;
    z-index: auto;
    flex-direction: column;
    align-items: flex-start;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
    overflow: hidden;
}
.title__folder{
    font-size: 12px;
    color: #fff;
}
.title__time{
    color: #8C8C8C;
    font-family: Avenir Next;
    font-size: 7px;
    font-style: italic;
}
.count__file{
    color: #8C8C8C;
    font-size: 8px;
}
.row__folder{
    position: relative;
    overflow: visible;
}
.grid-options{

}

.parrent__folder{
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease 0s;
}

.image__handle-grid.readers{
    background-color: rgb(0, 0, 0) !important;
}
.image__handle-grid{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 91%;
    width: 100%;
    flex-shrink: 0;
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
    background-color: rgb(102, 110, 122);
    position: relative;
}

.image__handle{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

/* END FOLDER STYLE */
.header__projects{
    border-bottom: 1px solid #2D2D2D;
}
.quiz-saya {
    background-color: black;
    height: calc(100vh - 80px);
	overflow: hidden
    /* widows: 14rem; */
}
.my-collections{
    /* border-bottom: .1px solid #2D2D2D; */
}

.my-collections h3{
    font-size: 24px;
}

.nav-link {
    padding: 0.5rem 1rem !important;
    margin: 0.5rem 0.5rem !important;
}

.collections-tab .nav-tabs {
    border: none;
}

.collections-tab li button{
    margin: 2rem;
    padding: 0.625rem 2.5rem;
    background-color: transparent;
    color: #8C8C8C;
    border-radius: 5px;
    border: none;
    transition: border 0.2s;
}

.collections-tab li button:hover {
    border: none;
}

.collections-tab li .active {
    background-color: #1f1f1f;
    color: white;
    border: none;
}

.collections-tab li .active:hover {
    border-color: #6D75F6 !important;
}

.header .search .form-control{
    padding: 10px 30px;
 }

.header label{
    position: relative;
    width: 50%;
}

.card .card-img-top {
    width: 100%;
    height: 12.5rem;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.card .card-img-overflow-y {
    width: 100%;
    height: 9.5rem;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.header label:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
}

.newest .card, .favorite .card{
    border-radius: 15px !important;
}

.newest p, .favorite p{
    color: #8C8C8C;
    margin: 0;
    font-size: 12px;
}

.newest .quiz, .favorite .quiz {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: auto;
}

.newest .quiz::-webkit-scrollbar, .favorite .quiz::-webkit-scrollbar{
    display: none;
}

.creator-info{
  border-radius: 12px;
  height: 25px;
  width: 50%;
  background-color: #F1F1F1;
}
.creator-info img {
  width: 18px;
  height: 18px;
}

.icon-start{
  width: 18px;
  height: 18px;
}

.card-info{
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #F1F1F1;
}

.img-sorry{
  width : 7.8rem;
  height : 8.3rem;
}

.zero-quiz h1{
  font-size: 40px;
}

.zero-quiz h3{
  font-size: 28px;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    border-radius: 40px;
}

.btn-purple-nb {
    background-color: #6D75F6;

    transition: background-color 0.25s;
}

.btn-purple-nb:hover {
    background-color: #545EED;
}

.btn-outline-purple {
    background-color: transparent;
    border: 1px solid #6D75F6;
    color: #6D75F6 !important;
}

.quiz-saya .modal-content {
    border-radius: 15px !important;
}

.sorting-by .form-control{
    border-radius: 20px;
    background-color: #222222;
    border: 0;
    color: white;
}

.card{
    border-radius: 10px;
    width: 12.5rem;
}

.make-content-container{
  background-color: #222222;
  border-radius: 5px;
}

.list-quiz{
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.list-quiz::-webkit-scrollbar {
    display: none;
}

.drafts-length{
    padding: 1px;
    min-width: 17px;
    height: 17px;
    border-radius:50%;
    background-color:red;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cc-icon {
  width: 14px;
  height: 14px;
}

.w-115{
  width:115%;
}

.desc-card p{
    font-size: 12px;
}

@media (max-width: 1366px){
    .card-w{
        width: auto;
    }
}

.lv__pill {
    padding: 0px 8px;
    background-color: #FB9C44;
    border-radius: 25px;
    width: auto;
    position: absolute;
    bottom: 42%;
    left: 10px;
}

.lv__pill-text {
    font-size: 10px;
    font-weight: 900;
    margin: 0;
}
</style>
<style>
.create-new .dropdown-menu.show {
    background: #1F1F1F;
    display: grid;
    width: 250px;
    text-align: left;
    box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
}

.create-new .dropdown-menu span {
    font-size: 12px;
    color: #585859;
}

.create-new .dropdown-item, .card-body .dropdown-item {
    color: white;
}

.create-new .dropdown-item:hover, .card-body .dropdown-item:hover {
    background-color: #8C8C8C;
}

.create-new .dropdown-menu p {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: white;
}

.card-body .dropdown-menu.show{
    background: #2d2d2d !important;
    color: white !important;
    box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
}

#delete-konten .modal-content {
    background-color: #1f1f1f;
    color: white;
}

.processing--dubs {
    color: #6D75F6;
    font-family: Avenir Next;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
</style>