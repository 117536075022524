<template>
    <div class="row pb-4 row__folder">
        <div class="parrent__folder mr-3" v-for="(items, index) in dataFolder" :key="index" @click="$emit('click:active-folder', index)" @dblclick="$router.push({ name : 'My-Quiz', query : { folder_name: items.folder_name }})">
            <svg xmlns="http://www.w3.org/2000/svg" width="199" height="141" viewBox="0 0 199 141" fill="none" :class="{ 'active--folder' : activeIndex == index }">
                <path d="M6.59474 140.8C3.09926 140.8 0.265625 137.967 0.265625 134.471V6.32913C0.265625 2.83365 3.09927 0 6.59474 0H77.3596C78.9057 0 80.3983 0.565923 81.5558 1.59096L97.8363 16.0091C98.9937 17.0341 100.486 17.6001 102.032 17.6001H192.671C196.166 17.6001 199 20.4337 199 23.9292V134.471C199 137.967 196.166 140.8 192.671 140.8H6.59474Z" fill="#1f1f1f"/>
            </svg>
                <div class="thumbnail__parent-folder grid-handle">
                    <div class="grid__img-parent grid-options" :data-items="items.image_thumbnail.length" v-if="items.tipe_data != 'Articles'">
                        <div class="image__handle-grid" :class="{'mt-2': idxThumb <= 1, 'mt-1': idxThumb > 1}" v-for="(thumb, idxThumb) in items.image_thumbnail" :key="idxThumb">
                            <img :src="thumb.image" alt="files-thumb" class="image__handle" :id="items.folder_name+'-'+idxThumb" @error="handleImgCrash(items.folder_name+'-'+idxThumb, items.folder_name)" v-if="thumb.image">
                            <div class="image__handle bg__default d-flex align-items-center justify-content-center" v-else-if="thumb.image == null && (items.folder_name == 'Videos' || items.folder_name == 'Dubbs')">
                                <img src="@/assets/images/icons/ic_generates-videos-project.svg" width="16">
                            </div>
                            <img :src="img_quiz_default" alt="files-thumb.image" class="image__handle" :id="items.folder_name+'-'+idxThumb" v-else-if="thumb.image == null && items.folder_name == 'Q-gen'">
                            <div class="more_files-wrapper" v-if="items.total_data > 3 && idxThumb === 2">
                                <span class="more__style">+{{ items.total_data - 3 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="grid__img-parent grid-options" :data-items="items?.image_thumbnail.length" v-else>
                        <div :class="{'mt-2': idxThumb <= 1, 'mt-1': idxThumb > 1}" class="image__handle-grid" v-for="(thumb, idxThumb) in items.image_thumbnail" :key="idxThumb">
                            <div class="image__handle bg__default d-flex align-items-center justify-content-center">
                                <img src="@/assets/images/icons/ic_news-reader-project.svg" width="16">
                            </div>
                            <!-- <span class="readers__text">{{ thumb.text }}</span> -->
                            <div class="more_files-wrapper" v-if="items.total_data > 3 && idxThumb === 2">
                                <span class="more__style">+{{ items.total_data - 3 }}</span>
                            </div>
                        </div>
                    </div>

                <div class="pr-folder__title-des">
                    <div class="folder__title-des">
                        <span class="title__folder mt-2">{{ items.folder_name }}</span>
                        <span class="count__file">{{ items.total_data +' '+ items.tipe_data }}</span>
                    </div>
                    <span class="title__time" v-if="items.folder_name != 'q-gen' && items.folder_name != 'dubbs' && items.total_data > 0">{{ getDate(items.date_update) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default{
    props: {
        dataFolder: {
            type: Array,
            default: () =>{
                return []
            }
        },
        activeIndex: {
            type: Number,
            default: null,
        }
    },
    data(){
        return{
            img_video_default: require('@/assets/images/background/cover_sound.png'),
            img_quiz_default: require('@/assets/images/default-cover.png'),
        }
    },
    methods: {
        handleImgCrash(img, tipe){
            let imgEl = document.getElementById(img);
            switch (tipe) {
                case 'videos':
                    imgEl.src = this.img_video_default;
                    break;
                case 'q-gen':
                    imgEl.src = this.img_quiz_default;
                    break;

                default:
                    break;
            }
        },
        getDate(item){
            const date = moment(item);
            let now = moment();
            now = now.add(420, 'minutes')
            const diff = now.diff(date, 'seconds');

            if (diff < 1) {
                return this.$t('a-few-secs-ago');
            } else if (diff < 60) {
                return this.$t('updated') + ' ' + diff + ' ' + this.$t('secs-ago');
            } else if (diff < 3600) {
                const minutes = Math.floor(diff / 60);
                return this.$t('updated') + ' ' + minutes + ' ' + this.$t('minutes-ago');
            } else if (diff < 86400) {
                const hours = Math.floor(diff / 3600);
                return this.$t('updated') + ' ' + hours + ' ' + this.$t('hours-ago');
            } else if (diff < 604800) {
                const days = Math.floor(diff / 86400);
                return this.$t('updated') + ' ' + days + ' ' + this.$t('days-ago');
            } else if (diff < 2592000) {
                const weeks = Math.floor(diff / 604800);
                return this.$t('updated') + ' ' + weeks + ' ' + this.$t('weeks-ago');
            } else if (diff < 31536000) {
                const months = Math.floor(diff / 2592000);
                return this.$t('updated') + ' ' + months + ' ' + this.$t('months-ago');
            } else {
                const years = Math.floor(diff / 31536000);
                return this.$t('updated') + ' ' + years + ' ' + this.$t('years-ago');
            }
        },
    }
}
</script>

<style scoped>
.grid-handle{
    position: absolute;
    inset: 0px;
    padding: 16px 8px 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.more_files-wrapper{
    position: absolute;
    inset: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}

.more__style{
    font-size: 10px;
    line-height: 20px;
    color: rgb(255, 255, 255);
    word-break: break-word;
    text-align: initial;
    max-width: 100%;
    max-height: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
}

.grid__img-parent{
    overflow: hidden;
    width: 100%;
    height: 100%;
    gap: 4px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
}
.grid-options[data-items="1"] {
    grid-template-areas:
    "one one one one"
        "one one one one";

}
.grid-options[data-items="2"] {
    grid-template-areas:
        "one one two two"
        "one one two two";
}
.grid-options[data-items="3"] {
    grid-template-areas:
    "one one two two"
    "one one three three";
}
.grid-options[data-items="4"] {
    grid-template-areas:
    "one one two two"
        "one one three four";}
.grid-options > .image__handle-grid:nth-child(1) {
    grid-area: one / one / one / one;
}
.grid-options > .image__handle-grid:nth-child(2) {
    grid-area: two / two / two / two;
}
.grid-options > .image__handle-grid:nth-child(3) {
    grid-area: three / three / three / three;
}
.grid-options > .image__handle-grid:nth-child(4) {
    grid-area: four / four / four / four;
}
.image__handle-grid.readers{
    background-color: rgb(0, 0, 0) !important;
}
.pr-folder__title-des{
    color: #fff;
    display: flex;
    box-sizing: border-box;
    min-width: initial;
    min-height: initial;
    max-width: none;
    max-height: none;
    z-index: auto;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: end;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
    overflow: hidden;
}
.folder__title-des{
    display: flex;
    box-sizing: border-box;
    min-width: initial;
    min-height: initial;
    max-width: none;
    max-height: none;
    z-index: auto;
    flex-direction: column;
    align-items: flex-start;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
    overflow: hidden;}
.title__folder{
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}
.title__time{
    color: #8C8C8C;
    font-family: Avenir Next;
    font-size: 7px;
    font-style: italic;
}
.count__file{
    color: #8C8C8C;
    font-size: 8px;
}
.row__folder{
    position: relative;
    overflow: visible;
}
.grid-options{

}

.parrent__folder:hover {
    transform: scale(1.05);
}

.parrent__folder{
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease 0s;
}
.image__handle-grid{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 90%;
    width: 100%;
    flex-shrink: 0;
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
    background-color: rgb(102, 110, 122);
    position: relative;
}
.image__handle{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.bg__default {
    background-color: #2d2d2d;
}

.readers__text{
    color: #FFF;
    text-align: center;
    font-family: Avenir Next;
    font-size: 8px;
}

.active--folder {
    stroke: #6D75F6;
    stroke-width: 2px;
}
</style>