<template>
    <div class="overflow-auto mt-2 pr-1" :class="{ 'content__view--grid' : dataContent.length > 0 , 'content__view--grid-detail' : dataContent.length > 0 && $route.query.folder_name, 'mt-4 content__view--grid-wsa' : dataContent.length > 0 && !$route.query.folder_name }">
		<template v-if="dataContent.length > 0">
			<div v-for="(data, index) in dataContent" :key="index" class="card vg__card position-relative mb-2">
				<img v-if="data.content_type == 'audio' && (data.image_thumbnail != null && data.image_thumbnail != 'undefined')" :src="data.image_thumbnail" :id="data.content_name+'-'+index" @error="handleImgCrash(data.content_name+'-'+index, 'audio')" :alt="data.content_name" class="img__vg cursor-pointer" :class="{ 'cursor-default' : data.content_type == 'auto-dubb' && !data.finished }" @click="$emit('click:edit-content', data, activeTab)">
				<div v-else-if="data.content_type == 'audio'&& (!data.image_thumbnail || data.image_thumbnail == 'undefined')" class="folder__vg d-flex align-items-center justify-content-center cursor-pointer" @click="$emit('click:edit-content', data, activeTab)">
                    <img src="@/assets/images/icons/ic_generates-videos-project.svg">
				</div>
				<div v-else-if="data.content_type == 'quiz' && data.images_cover" class="folder__vg d-flex align-items-center justify-content-center cursor-pointer">
					<img :src="data.images_cover" alt="Folder" class="img__vg" :id="data.quiz_name+'-'+index" @error="handleImgCrash(data.quiz_name+'-'+index, 'quiz')" @click="$emit('click:edit-content', data, activeTab)">
				</div>
				<div v-else-if="data.content_type == 'quiz' && !data.images_cover || data.images_cover == 'undefinded'" class="folder__vg d-flex align-items-center justify-content-center cursor-pointer">
					<img :src="img_quiz_default" alt="Folder" class="img__vg" @click="$emit('click:edit-content', data, activeTab)">
				</div>
				<div v-else-if="data.content_type == 'news_reader'" class="folder__vg d-flex align-items-center justify-content-center cursor-pointer" @click="$emit('click:edit-content', data, activeTab)">
                    <img src="@/assets/images/icons/ic_news-reader-project.svg">
				</div>
				<div v-else-if="data.content_type == 'auto-dubb'" class="img__ad d-flex align-items-center justify-content-center cursor-pointer position-relative" :class="{ 'cursor-default' : data.content_type == 'auto-dubb' && !data.finished }" @click="$emit('click:edit-content', data, activeTab)">
					<img v-if="data.image_thumbnail" :src="data.image_thumbnail" :id="data.content_name+'-'+index" @error="handleImgCrash(data.content_name+'-'+index, 'audio')" :alt="data.content_name" class="w-100 h-100 cursor-pointer img__vg" @click="$emit('click:edit-content', data, activeTab)">
					<div v-else class="folder__vg d-flex align-items-center justify-content-center cursor-pointer" @click="$emit('click:edit-content', data, activeTab)">
						<img src="@/assets/images/icons/ic_generates-videos-project.svg">
					</div>
					<div v-if="!data.content_file" class="auto--dub__generating  d-flex flex-column align-items-center justify-content-center position-absolute px-3 w-100 h-100">
						<img src="@/assets/images/icons/ic_generating-autodub.svg" alt="">
						<p class="auto--dub__generating-text m-0">Generating...</p>
						<div class="progress auto-dub__progress w-100 mx-5 mt-2">
							<div class="progress-bar" role="progressbar" :style="'width:' + data.progress_percentage + '%'" :aria-valuenow="data.progress_percentage" aria-valuemin="0" aria-valuemax="100"></div>
						</div>
					</div>
				</div>
				<div v-else-if="data?.embed_code != null" class="folder__vg d-flex align-items-center justify-content-center cursor-pointer" @click="$emit('click:edit-content', data, activeTab)">
                    <img src="@/assets/images/icons/ic_news-reader-project.svg">
				</div>
				<div v-else-if="data.image_thumbnail == 'undefined'" class="folder__vg d-flex align-items-center justify-content-center cursor-pointer" @click="$emit('click:edit-content', data, activeTab)">
					<img src="@/assets/images/icons/ic_generates-videos-project.svg">
				</div>
				<img v-else-if="!data.images_cover" :src="img_quiz_default" :id="data.quiz_name+'-'+index" @error="handleImgCrash(data.quiz_name+'-'+index, 'quiz')" :alt="data.quiz_name" class="img__vg cursor-pointer" @click="$emit('click:edit-content', data, activeTab)">
				<img v-else-if="data.images_cover" :src="data.images_cover" :id="data.quiz_name+'-'+index" @error="handleImgCrash(data.quiz_name+'-'+index, 'quiz')" :alt="data.quiz_name" class="img__vg cursor-pointer" @click="$emit('click:edit-content', data, activeTab)">
					<div class="card-body p-2">
						<div class="d-flex align-items-center justify-content-between">
							<p class="card__title m-0 text-truncate" :title="data.content_name" v-if="data.content_name">{{ data.content_name }}</p>
							<p class="card__title m-0 text-truncate" :title="data.quiz_name" v-else-if="data.quiz_name">{{ data.quiz_name ? data.quiz_name : '-' }}</p>
							<p class="card__title m-0 text-truncate" :title="data.quiz_name" v-else>-</p>
							<div v-if="data.content_type == 'auto-dubb' && data.content_file" class="dropdown map__dropdown">
								<button class="map__button-action btn p-0" type="button" data-toggle="dropdown" aria-expanded="false">
									<b-icon icon="three-dots-vertical" style="color: #FFFFFF" font-scale="0.8"/>
								</button>
								<div class="dropdown-menu dropdown-menu-right fade">
									<a class="dropdown-item text-white px-2 cursor-pointer" @click="$emit('click:delete', data)">{{ $t('delete') }}</a>
									<a class="dropdown-item text-white px-2 cursor-pointer" v-if="(data.content_type == 'audio' || data.content_type == 'news_reader')  && activeTab == 'recents' || data.content_name && activeTab == 'open_file'" @click="$emit('click:handle-rename', data)">{{ $t('change-name') }}</a>
								</div>
							</div>
							<div v-else-if="data.content_type != 'auto-dubb'" class="dropdown map__dropdown">
								<button class="map__button-action btn p-0" type="button" data-toggle="dropdown" aria-expanded="false">
									<b-icon icon="three-dots-vertical" style="color: #FFFFFF" font-scale="0.8"/>
								</button>
								<div class="dropdown-menu dropdown-menu-right fade">

									<a class="dropdown-item text-white px-2 cursor-pointer" v-if="data.content_type == 'quiz' && activeTab == 'recents' || data.quiz_name && activeTab == 'open_file'" @click="$emit('click:share-quiz', data, 1)">Share</a>
									<a class="dropdown-item text-white px-2 cursor-pointer" v-else-if="activeTab == 'favorites'" @click="$emit('click:share-quiz', data, data.author_quiz)">Share</a>

									<a class="dropdown-item text-white px-2 cursor-pointer" v-if="data.content_type == 'quiz' && activeTab == 'recents' || data.quiz_name && activeTab == 'open_file'" @click="$emit('click:duplicate-quiz', data._id, 1)">{{ $t('duplicate') }}</a>
									<a class="dropdown-item text-white px-2 cursor-pointer" v-else-if="activeTab == 'favorites'" @click="$emit('click:duplicate-quiz', data._id, data.author_quiz)">{{ $t('duplicate') }}</a>

									<a class="dropdown-item text-white px-2 cursor-pointer" @click="$emit('click:delete', data, true)" v-if="activeTab == 'favorites'">{{ $t('delete') }}</a>
									<a class="dropdown-item text-white px-2 cursor-pointer" @click="$emit('click:delete', data)" v-else>{{ $t('delete') }}</a>

									<a class="dropdown-item text-white px-2 cursor-pointer" v-if="(data.content_type == 'audio' || data.content_type == 'news_reader')  && activeTab == 'recents' || data.content_name && activeTab == 'open_file'" @click="$emit('click:handle-rename', data)">{{ $t('change-name') }}</a>
								</div>
							</div>
						</div>
						<div v-if="data.quiz_name != null || data.content_type == 'quiz'" class="d-flex aling-items-center justify-content-between mt-1">
							<div class="d-flex align-items-center">
								<img
									src="@/assets/images/icons/ic_play_grup_grey.svg"
									alt=""
									class="user-svg img-fluid"
								/>
								<p class="ml-1 mb-0 mr-3">{{ data.total_play ? data.total_play : '0'}}</p>
								<img
									src="@/assets/images/icons/ic_question_grup_grey.svg"
									alt=""
									class="user-svg img-fluid"
								/>
								<p class="ml-1 mb-0">{{ data.total_question ? data.total_question : '0' }}</p>
							</div>
							<div class="d-flex align-items-end">
								<img src="@/assets/images/icons/ic_star_outline.svg" alt="">
								<p class="ml-1 mb-0">{{ data.score ? data.score : '0' }}</p>
							</div>
						</div>
						<div v-else class="d-flex aling-items-center justify-content-end mt-4" :class="{ 'justify-content-between' :  data.content_type == 'auto-dubb' }">
							<p v-if="data.content_type == 'auto-dubb'" class="m-0 card__text-detail size_file">{{ data.content_file_size ? convertToBytes(data.content_file_size) : '0kb' }}</p>
							<!-- <p v-else class="m-0 card__text-detail size_file">{{ data.total_file ? data.total_file : '-' }} </p> -->
							<p class="m-0 card__text-detail">{{ getDate(data.updatedAt) }}</p>
						</div>
						<p v-if="data.quiz_name != null || data.content_type == 'quiz'" class="m-0 card__text-detail text-left">{{ getDate(data.updatedAt) }}</p>
					</div>
			</div>
		</template>
		<template v-else>
			<div class="d-flex justify-content-center">
                <span class="text-white text-center mt-5" v-if="activeTab == 'open_file'">You don't have {{ $route.query.folder_name == 'Readers' ? $route.query.folder_name+' Articles' : $route.query.folder_name == 'Q-gen' ? $route.query.folder_name+ ' Quizzes' : $route.query.folder_name + ' Files' }} yet</span>
                <span class="text-white text-center mt-5" v-if="activeTab == 'recents'">You have never opened any content</span>
                <span class="text-white text-center mt-5" v-if="activeTab == 'favorites'">You don't have favorite content</span>
                <span class="text-white text-center mt-5" v-if="activeTab == 'draft'">You don't have content drafts</span>
            </div>
		</template>
	</div>
</template>

<script>
import moment from 'moment';
export default{
    props: {
		activeTab: {
            type: String,
            default: "recents"
        },
        dataContent: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data(){
        return{
			img_quiz_default: require('@/assets/images/default-cover.png'),
			img_video_default: require('@/assets/images/background/cover_sound.png'),
        }
    },
	emits: ['click:duplicate-quiz', 'click:delete', 'click:edit-quiz', 'click:quiz-audio', 'click:share-quiz', 'click:edit-content'],
    methods: {
		handleImgCrash(img, tipe){
            let imgEl = document.getElementById(img);
            switch (tipe) {
                case 'audio':
                    imgEl.src = this.img_video_default;
                    break;
                case 'quiz':
                    imgEl.src = this.img_quiz_default;
                    break;

                default:
                    break;
            }
        },
        convertToBytes(num) {
			const number = parseInt(num);
			const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			let unitIndex = 0;
			let size = number;

			while (size >= 1024 && unitIndex < units.length - 1) {
				size /= 1024;
				unitIndex++;
			}

			return `${size.toFixed(2)} ${units[unitIndex]}`;
		},
        getDate(item){
            const date = moment(item);
            let now = moment();
            now = now.add(420, 'minutes')
            const diff = now.diff(date, 'seconds');

            if (diff < 1) {
                return this.$t('a-few-secs-ago');
            } else if (diff < 60) {
                return diff + ' ' + this.$t('secs-ago');
            } else if (diff < 3600) {
                const minutes = Math.floor(diff / 60);
                return minutes + ' ' + this.$t('minutes-ago');
            } else if (diff < 86400) {
                const hours = Math.floor(diff / 3600);
                return hours + ' ' + this.$t('hours-ago');
            } else if (diff < 604800) {
                const days = Math.floor(diff / 86400);
                return days + ' ' + this.$t('days-ago');
            } else if (diff < 2592000) {
                const weeks = Math.floor(diff / 604800);
                return weeks + ' ' + this.$t('weeks-ago');
            } else if (diff < 31536000) {
                const months = Math.floor(diff / 2592000);
                return months + ' ' + this.$t('months-ago');
            } else {
                const years = Math.floor(diff / 31536000);
                return years + ' ' + this.$t('years-ago');
            }
        },
    }
}
</script>

<style scoped>
.content__view--grid, .content__view--grid-detail {
	display: grid;
	grid-template-columns: repeat(6, 0.17fr);
	grid-template-rows: repeat(5, 1fr);
	grid-gap: 0.6rem;
	grid-row: 1;
}
.content__view--grid-detail {
	height: calc(100vh - 250px);
}

.card__text-detail.size_file{
    font-style: normal;
}
.card__text-detail{
    color: #8C8C8C;
    font-family: Avenir Next;
    font-size: 10px;
    font-style: italic;
}

.content__view--grid-wsa {
	height: calc(100vh - 450px);
}
.vg__card:hover {
	/* transform: scale(1.01); */
}
.vg__card {
	background-color: #1f1f1f;
	border-radius: 8px;
	/* max-width: 165px; */
	width: 100%;
	height: 190px;
	transition: transform 0.3s ease 0s;
}

.img__vg, .folder__vg, .img__ad {
	width: 100%;
	height: 110px;
	border-radius: 6px 6px 0 0;
	object-fit: cover;
}

.folder__vg {
	background-color: #2d2d2d;
}

.card__title {
	font-size: 14px;
}

.auto-dub__progress {
	border-radius: 4px;
	height: 5px;
	background-color: #3E4350;
}

.auto-dub__progress .progress-bar {
	background-color: #6D75F6;
	border-radius: 4px;
}

.auto--dub__generating {
	background: rgba(0, 0, 0, 0.50);
	backdrop-filter: blur(1px);
}

.cursor-default {
	cursor: default !important;
}
</style>