<template>
    <b-table hover :items="dataContent" :fields="fields" @row-clicked="handleEditContent" sticky-header show-empty thead-tr-class="header__row" tbody-tr-class="body__row" class="projects__table-class m-0" :class="{ 'table__detail' : $route.query.folder_name }">
        <!-- HEAD -->
        <template #head(name)="data">
            <div class="text-left">
                {{ $t(data.label.toLowerCase()) }}
            </div>
        </template>

        <template #head(type)="data">
            <div class="text-left">
                {{ $t(data.label.toLowerCase()) }}
            </div>
        </template>

        <template #head(updated)>
            <div class="text-left">
                {{ $t('updated').toUpperCase() }}
            </div>
        </template>
        <!-- END HEAD -->
        <template #empty>
            <div class="d-flex justify-content-center">
                <span class="text-white text-center mt-5" v-if="activeTab == 'open_file'">You don't have {{ $route.query.folder_name == 'Readers' ? $route.query.folder_name+' Articles' : $route.query.folder_name == 'Q-gen' ? $route.query.folder_name+ ' Quizzes' : $route.query.folder_name + ' Files' }} yet</span>
                <span class="text-white text-center mt-5" v-if="activeTab == 'recents'">You have never opened any content</span>
                <span class="text-white text-center mt-5" v-if="activeTab == 'favorites'">You don't have favorite content</span>
                <span class="text-white text-center mt-5" v-if="activeTab == 'draft'">You don't have content drafts</span>
            </div>
        </template>
        <template #cell(name)="data">
            <div class="d-flex align-items-center" v-if="data.item.content_type == 'quiz'">
                <img :src=data.item.images_cover alt="image__cover-quiz" class="image__cover-quiz" v-if="data.item.images_cover != null && data.item.images_cover != 'undefined' && data.item.images_cover != 'null'">
                <img src="@/assets/images/default-cover.png" alt="image__cover-quiz" class="image__cover-quiz" v-else >
                <p class="quiz__name ml-2 mb-0">{{ data.item.content_name }}</p>
            </div>
            <div class="d-flex align-items-center" v-else-if="data.item.content_type == 'news_reader' && activeTab != 'draft'">
                <div class="bg__news-reader d-flex align-items-center justify-content-center">
                    <img src="@/assets/images/icons/ic_news-reader-project.svg" width="16">
                </div>
                <p class="quiz__name ml-2 mb-0">{{ data.item.content_name }}</p>
            </div>
            <div class="d-flex align-items-center" v-else-if="data.item.content_type == 'audio' && activeTab != 'draft'">
                <img :src=data.item.image_thumbnail alt="image__cover-audio" class="image__cover-audio" v-if="data.item.image_thumbnail != null && data.item.image_thumbnail != 'undefined' && data.item.image_thumbnail != 'null'">
                <div class="bg__news-reader d-flex align-items-center justify-content-center" v-else>
                    <img src="@/assets/images/icons/ic_generates-videos-project.svg" width="16">
                </div>
                <p class="quiz__name ml-2 mb-0">{{ data.item.content_name }}</p>
            </div>
            <div class="d-flex align-items-center" v-else-if="data.item.embed_code && activeTab != 'draft'">
                <div class="bg__news-reader d-flex align-items-center justify-content-center">
                    <img src="@/assets/images/icons/ic_news-reader-project.svg" width="16">
                </div>
                <p class="quiz__name ml-2 mb-0">{{ data.item.content_name }}</p>
            </div>
            <div class="d-flex align-items-center" v-else-if="data.item.content_type == 'auto-dubb'">
                <img :src=data.item.image_thumbnail alt="image__cover-quiz" class="image__cover-quiz" v-if="data.item.image_thumbnail != null && data.item.image_thumbnail != 'undefined' && data.item.image_thumbnail != 'null'">
                <div class="bg__news-reader d-flex align-items-center justify-content-center" v-else>
                    <img src="@/assets/images/icons/ic_generates-videos-project.svg" width="16">
                </div>
                <p class="quiz__name ml-2 mb-0">{{ data.item.content_name }}</p>
            </div>
            <div class="d-flex align-items-center" v-else-if="activeTab == 'favorites'">
                <img :src=data.item.images_cover alt="image__cover-audio" class="image__cover-quiz" v-if="data.item.images_cover != null && data.item.images_cover != 'undefined' && data.item.images_cover != 'null'">
                <img src="@/assets/images/default-cover.png" alt="image__cover-quiz" class="image__cover-quiz" v-else >
                <p class="quiz__name ml-2 mb-0">{{ data.item.quiz_name }}</p>
            </div>
            <div class="d-flex align-items-center" v-else-if="activeTab == 'open_file'">
                <img :src=data.item.images_cover alt="image__cover-audio" class="image__cover-quiz" v-if="data.item.images_cover != null && data.item.images_cover != 'undefined' && data.item.images_cover != 'null'">
                <img src="@/assets/images/default-cover.png" alt="image__cover-quiz" class="image__cover-quiz" v-else >
                <p class="quiz__name ml-2 mb-0">{{ data.item.quiz_name }}</p>
            </div>
            <div class="d-flex align-items-center" v-else-if="activeTab == 'draft'">
                <template v-if="data.item.quiz_name">
                    <img :src=data.item.images_cover alt="image__cover-audio" class="image__cover-quiz" v-if="data.item.images_cover != null && data.item.images_cover != 'undefined' && data.item.images_cover != 'null'">
                    <img src="@/assets/images/default-cover.png" alt="image__cover-quiz" class="image__cover-quiz" v-else >
                    <p class="quiz__name ml-2 mb-0">{{ data.item.quiz_name }}</p>
                </template>
                <template v-if="data.item.content_name">
                    <img :src=data.item.image_thumbnail alt="image__cover-audio" class="image__cover-quiz" v-if="data.item.image_thumbnail != null && data.item.image_thumbnail != 'undefined' && data.item.image_thumbnail != 'null'">
                    <div class="bg__news-reader d-flex align-items-center justify-content-center" v-else>
                        <img src="@/assets/images/icons/ic_generates-videos-project.svg" width="16">
                    </div>
                    <p class="quiz__name ml-2 mb-0">{{ data.item.content_name }}</p>
                </template>
            </div>
        </template>
        <template #cell(type)="data">
            <span v-if="activeTab != 'favorites' && activeTab != 'open_file' && activeTab != 'draft'">{{ data.item.content_type == 'audio' ? 'video' : data.item.content_type == 'news_reader' ? 'news reader' : data.item.content_type == 'quiz' ? 'quizzes' : data.item.content_type }}</span>
            <span v-else-if="(activeTab == 'open_file' || activeTab == 'draft') && (data.item.content_name && !data.item.embed_code)">video</span>
            <span v-else-if="(activeTab == 'open_file' || activeTab == 'draft') && (data.item.embed_code && !data.item.quiz_name)">news reader</span>
            <span v-else-if="activeTab == 'open_file' || activeTab == 'draft' && data.item.quiz_name">quizzes</span>
            <span v-else>quizzes</span>
        </template>
        <template #cell(updated)="data">
            <span>{{ getDate(data.item.updatedAt) }}</span>
        </template>
        <template #cell(action)="data">
				<div class="dropdown map__dropdown">
					<button class="map__button-action btn p-0" type="button" data-toggle="dropdown" aria-expanded="false">
						<font-awesome-icon icon="fa-solid fa-ellipsis" class="map__ba-icon"/>
					</button>
					<div class="dropdown-menu dropdown-menu-right fade">

                        <a class="dropdown-item text-white px-2 cursor-pointer" v-if="data.item.content_type == 'quiz' && activeTab == 'recents' || data.item.quiz_name && activeTab == 'open_file'" @click="$emit('click:share-quiz', data.item, 1)">Share</a>
                        <a class="dropdown-item text-white px-2 cursor-pointer" v-else-if="activeTab == 'favorites'" @click="$emit('click:share-quiz', data.item, data.item.author_quiz)">Share</a>

                        <a class="dropdown-item text-white px-2 cursor-pointer" v-if="data.item.content_type == 'quiz' && activeTab == 'recents' || data.item.quiz_name && activeTab == 'open_file'" @click="$emit('click:duplicate-quiz', data.item._id, 1)">{{ $t('duplicate') }}</a>
						<a class="dropdown-item text-white px-2 cursor-pointer" v-else-if="activeTab == 'favorites'" @click="$emit('click:duplicate-quiz', data.item._id, data.item.author_quiz)">{{ $t('duplicate') }}</a>

						<!-- <template v-if="data.item.content_type == 'quiz'">
                            <a class="dropdown-item text-white px-2 cursor-pointer" v-if="activeTab == 'draft'" @click="$emit('click:edit-quiz', data.item._id, 'draft')">Edit</a>
						    <a class="dropdown-item text-white px-2 cursor-pointer" v-else-if="activeTab != 'draft'" @click="$emit('click:edit-quiz', data.item._id, 'new')">Edit</a>
                        </template> -->
                        <a class="dropdown-item text-white px-2 cursor-pointer" @click="$emit('click:delete', data.item, true)" v-if="activeTab == 'favorites'">{{ $t('delete') }}</a>
                        <a class="dropdown-item text-white px-2 cursor-pointer" @click="$emit('click:delete', data.item)" v-else>{{ $t('delete') }}</a>

                        <a class="dropdown-item text-white px-2 cursor-pointer" v-if="(data.item.content_type == 'audio' || data.item.content_type == 'news_reader' || data.item.content_type == 'auto-dubb') && activeTab == 'recents' || data.item.content_name && activeTab == 'open_file'" @click="$emit('click:handle-rename', data.item)">{{ $t('change-name') }}</a>
					</div>
				</div>
		</template>
    </b-table>
</template>

<script>
import moment from 'moment';

export default {
    data(){
        return{
            fields: ['name', 'type', {key: 'updated',}, {  key: 'action', label: '' }],
        }
    },
    props: {
        activeTab: {
            type: String,
            default: "recents"
        },
        dataContent: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    emits: ['click:duplicate-quiz', 'click:delete', 'click:edit-quiz', 'click:quiz-audio', 'click:share-quiz', 'click:edit-content'],
    methods: {
        handleEditContent(item){
            this.$emit('click:edit-content', item, this.activeTab)
        },
        getDate(item){
            const date = moment(item);
            let now = moment();
            now = now.add(420, 'minutes')
            const diff = now.diff(date, 'seconds');

            if (diff < 1) {
                return this.$t('a-few-secs-ago');
            } else if (diff < 60) {
                return diff + ' ' + this.$t('secs-ago');
            } else if (diff < 3600) {
                const minutes = Math.floor(diff / 60);
                return minutes + ' ' + this.$t('minutes-ago');
            } else if (diff < 86400) {
                const hours = Math.floor(diff / 3600);
                return hours + ' ' + this.$t('hours-ago');
            } else if (diff < 604800) {
                const days = Math.floor(diff / 86400);
                return days + ' ' + this.$t('days-ago');
            } else if (diff < 2592000) {
                const weeks = Math.floor(diff / 604800);
                return weeks + ' ' + this.$t('weeks-ago');
            } else if (diff < 31536000) {
                const months = Math.floor(diff / 2592000);
                return months + ' ' + this.$t('months-ago');
            } else {
                const years = Math.floor(diff / 31536000);
                return years + ' ' + this.$t('years-ago');
            }
        },
    }
}
</script>

<style>
#project .header__row > th > div{
    color: #8C8C8C;
    text-transform: uppercase;
    text-align: left;
}

#project .body__row td{
    border-top: 1px solid #2D2D2D;
    color: #8C8C8C;
    font-family: Avenir Next;
    font-size: 14px;
    text-align: left;
}

#project tr.body__row{
    cursor: pointer;
}

.projects__table-class tbody tr{
    outline:#2d2d2d 1px solid;
	font-weight: 400;
}

.projects__table-class thead th {
	font-weight: 400;
}
.b-table-empty-row{
    cursor: default !important;
}

.projects__table-class tbody tr:not(.b-table-empty-row):hover {
	background: rgba(109, 117, 246, 0.20) !important;
    outline:#6D75F6 1px solid;
	/* border: 1px solid #6D75F6; */
}

.projects__table-class {
	max-height: calc(100vh - 450px);
}

.table__detail {
	max-height: calc(100vh - 242px);
}

.projects__table-class .table-b-table-default {
	color: #8c8c8c !important;
	background-color: black !important;
}

.projects__table-class thead tr th {
    top: -1px !important;
}

</style>

<style scoped>

.quiz__name{
    color: #fff !important;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}
.image__cover-quiz, .image__cover-audio, .bg__news-reader{
    width: 40px;
    height: 40px;
    border-radius: 6px;
    object-fit: cover;
}

.bg__news-reader {
    background-color: #2d2d2d;
}

.title__general{

}

.map__dropdown .dropdown-menu{
	background: #2D2D2D;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
	border-radius: 6px;
	color: #fff;
	min-width: 100px !important;
	max-width: 100px !important;
}

.map__dropdown .dropdown-item:hover {
	background-color: #8c8c8c;
	color: #fff;
}
.map__button-action {
	box-shadow: none;
	color: #fff;
}
</style>