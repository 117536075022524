<template>
    <div class="tab-content mt-3" id="quizTabContent">
        <div class="tab-pane fade show active text-white" id="recent" role="tabpanel" aria-labelledby="quiz-tab">
            <List
                v-if="activeDisplay == 'List'"
                :dataContent="contentRecent"
                :activeTab="activeTab"
                @click:duplicate-quiz="handlePassingDuplicateQuiz"
                @click:delete="handlePassingDelete"
                @click:share-quiz="handlePassingShareQuiz"
                @click:handle-rename="handlePassingRenameVideo"
                @click:edit-content="handlePassingEditContent"
            />
            <Gallery v-else
                :dataContent="contentRecent"
                :activeTab="activeTab"
                @click:duplicate-quiz="handlePassingDuplicateQuiz"
                @click:delete="handlePassingDelete"
                @click:share-quiz="handlePassingShareQuiz"
                @click:handle-rename="handlePassingRenameVideo"
                @click:edit-content="handlePassingEditContent"
                />
        </div>
        <div class="tab-pane fade text-white" id="favorites" role="tabpanel" aria-labelledby="favorites-tab">
            <List v-if="activeDisplay == 'List'"
                :dataContent="contentFav"
                :activeTab="activeTab"
                @click:duplicate-quiz="handlePassingDuplicateQuiz"
                @click:delete="handlePassingDelete"
                @click:share-quiz="handlePassingShareQuiz"
                @click:edit-content="handlePassingEditContent"
            />
            <Gallery v-else
                :dataContent="contentFav"
                :activeTab="activeTab"
                @click:duplicate-quiz="handlePassingDuplicateQuiz"
                @click:delete="handlePassingDelete"
                @click:share-quiz="handlePassingShareQuiz"
                @click:edit-content="handlePassingEditContent"
            />
        </div>
        <div class="tab-pane fade text-white" id="drafts" role="tabpanel" aria-labelledby="drafts-tab">
            <List v-if="activeDisplay == 'List'"
                :dataContent="contentDrafts"
                :activeTab="activeTab"
                @click:duplicate-quiz="handlePassingDuplicateQuiz"
                @click:delete="handlePassingDelete"
                @click:share-quiz="handlePassingShareQuiz"
                @click:handle-rename="handlePassingRenameVideo"
                @click:edit-content="handlePassingEditContent"
                />
            <Gallery v-else
                :dataContent="contentDrafts"
                :activeTab="activeTab"
                @click:duplicate-quiz="handlePassingDuplicateQuiz"
                @click:delete="handlePassingDelete"
                @click:share-quiz="handlePassingShareQuiz"
                @click:handle-rename="handlePassingRenameVideo"
                @click:edit-content="handlePassingEditContent"
            />
        </div>
    </div>
</template>

<script>
import List from './List';
import Gallery from './Gallery';
export default {
    props: {
        activeDisplay: {
            type: String,
            default: "List"
        },
        activeTab: {
            type: String,
            default: "recents"
        },
        contentRecent: {
            type: Array,
            default: () => {
                return []
            }
        },
        contentDrafts: {
            type: Array,
            default: () => {
                return []
            }
        },
        contentFav: {
            type: Array,
            default: () => {
                return []
            }
        },
        dataContent: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    components: {
        List,
        Gallery
    },
    emits: ['click:duplicate-quiz', 'click:delete', 'click:edit-quiz', 'click:quiz-audio', 'click:share-quiz', 'click:handle-rename'],
    data(){
        return{
        }
    },
    methods: {
        handlePassingEditContent(data, tab){
            if(tab == 'draft'){
                if(data.content_type == 'quiz'){
                    this.$emit('click:edit-quiz', data._id, 'draft')
                }else if(data.content_type == 'audio'){
                    this.$emit('click:edit-audio', data._id, 'draft')
                }else{
                    this.$emit('click:edit-quiz', data._id, 'edit')
                }
            } else if (tab == 'favorites') {
                this.$emit('click:to-library-qgen', data._id, data.author_quiz)
            } else {
                if(data.content_type == 'quiz'){
                    this.$emit('click:edit-quiz', data._id, 'edit')
                }else if(data.content_type == 'audio'){
                    this.$emit('click:edit-audio', data._id, 'edit')
                } else if(data.content_type == 'news_reader') {
                    this.$emit('click:edit-news-reader', data._id)
                } else if(data.embed_code) {
                    this.$emit('click:edit-news-reader', data._id)
                } else if (data.content_type == 'auto-dubb') {
                    this.$emit('click:preview-auto-dubb', data)
                } else {
                    this.$emit('click:edit-quiz', data._id, 'edit')
                }
            }
        },
        handlePassingShareQuiz(data, author){
            this.$emit('click:share-quiz', data._id, author)
        },
        handlePassingRenameVideo(data){
            let type = data.content_type == 'news_reader' || data.embed_code ? 'nr' : data.content_type == 'auto-dubb' ? 'ad' : null
            this.$emit('click:handle-rename', data._id, data.content_name, type)
        },
        handlePassingDelete(data, unrate){

            if(unrate){
                this.$emit('click:delete', data._id, 'unrating',data.author_quiz)
            }else{
                if(!data.content_type){
                    if(data.content_name && !data.embed_code){
                        this.$emit('click:delete', data._id, 'audio')
                    } else if(data.embed_code) {
                        this.$emit('click:delete', data._id, 'news_reader')
                    } else if(data.quiz_name){
                        this.$emit('click:delete', data._id, 'quiz')
                    }
                }else{
                    this.$emit('click:delete', data._id, data.content_type)
                }
            }
        },
        handlePassingDuplicateQuiz(id, author_quiz){
            this.$emit('click:duplicate-quiz', id, author_quiz)
        },
    }
}
</script>

<style>
#quizTabContent .table-hover tbody tr:hover {
    color: #2b2b2b;
    background-color: rgba(43, 43, 43);
    transition: background-color 0.3s ease;
}
</style>