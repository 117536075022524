<template>
    <div class="quiz-saya">
        <div v-if="loading">
            <div class="header container-fluid">
                <div>
                    <h3 class="text-left">Quiz Saya</h3>
                </div>
                <div class="mt-3 d-flex justify-content-start">
                    <label for="seacrh" class="text-center search">
                        <b-form-input v-model="filter" placeholder="Cari Konten"></b-form-input>
                    </label>
                    <select name="" id="filter" class="form-control ml-3 col-1">
                        <option value="">Semua</option>
                    </select>
                </div>
            </div>

            <div class="newest">
                <div class="container-fluid mt-5">
                    <h3 class="text-left">My Collections</h3>
                    <div class="quiz d-flex mt-3">
                        <div v-for="index in 4" :key="index">
                            <div class="card mr-3" style="width: 18rem;">
                                <b-skeleton-img card-img="top"></b-skeleton-img>
                                <div class="card-body text-left">
                                    <div class="d-flex justify-content-between mb-2">
                                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    </div>
                                    <div class="d-flex mb-2">
                                        <b-skeleton animation="wave" width="50%"></b-skeleton>
                                    </div>
                                    <b-skeleton animation="wave" width="80%"></b-skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="favorite">
                <div class="container-fluid mt-5">
                    <h3 class="text-left">Favorite</h3>
                    <div class="quiz d-flex mt-3">
                        <div v-for="index in 4" :key="index">
                            <div class="card mr-3" style="width: 18rem;">
                                <b-skeleton-img card-img="top"></b-skeleton-img>
                                <div class="card-body text-left">
                                    <div class="d-flex justify-content-between mb-2">
                                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    </div>
                                    <div class="d-flex mb-2">
                                        <b-skeleton animation="wave" width="50%"></b-skeleton>
                                    </div>
                                    <b-skeleton animation="wave" width="80%"></b-skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="quizCreator.length > 0">
                <div class="header container-fluid">
                    <div>
                        <h3 class="text-left">Quiz Saya</h3>
                    </div>
                    <div class="mt-3 d-flex justify-content-start">
                        <label for="seacrh" class="text-center search">
                            <b-form-input v-model="filter" placeholder="Cari Konten"></b-form-input>
                        </label>
                        <select name="" id="filter" class="form-control ml-3 col-1">
                            <option value="">Semua</option>
                        </select>
                    </div>
                </div>

                <div class="newest">
                    <div class="container-fluid mt-5">
                        <h3 class="text-left">My Collections</h3>
                        <div class="quiz d-flex mt-3">
                            <div v-for="(quiz, index) in searchMyCollections" :key="index">
                                <div class="card mr-3" style="width: 18rem;">
                                    <img :src=quiz.images_cover alt="" type="button" v-if="quiz.images_cover != null && quiz.images_cover != 'undefined'" class="card-img-top" @click="editQuizSaya(quiz._id)">
                                    <img src="@/assets/images/default-cover.png" type="button" @click="editQuizSaya(quiz._id)" alt="" class="card-img-top" v-else >
                                    <div class="card-body text-left">
                                        <div class="d-flex justify-content-between align-items-start">
                                        <h5 class="card-title text-truncate">{{ quiz.quiz_name }}</h5>
                                        <b-dropdown size="md"  variant="link" toggle-class="text-decoration-none text-dark p-0" no-caret>
                                            <template #button-content>
                                                <b-icon icon="three-dots-vertical"></b-icon>
                                            </template>
                                            <b-dropdown-item @click="share(quiz._id, 1)" class="d-flex align-items-center"><img src="@/assets/images/icons/share-icon.svg" alt="" class="user-svg mr-2">Share</b-dropdown-item>
                                            <b-dropdown-item class="d-flex align-items-center" @click="detailQuiz(quiz._id, quiz.author_quiz)"><img src="@/assets/images/icons/duplicate-icon.svg" alt="" class="user-svg mr-2">Duplicate</b-dropdown-item>
                                            <b-dropdown-item class="d-flex align-items-center" @click="modalDelete(quiz._id)"><img src="@/assets/images/icons/ic_delete.svg" alt="" class="user-svg mr-2">Delete</b-dropdown-item>
                                        </b-dropdown>
                                        </div>
                                        <div class="d-flex justify-content-start mb-2">
                                        <div class="d-flex mr-4">
                                            <img
                                                src="@/assets/images/icons/ic_play_grup_grey.svg"
                                                alt=""
                                                class="user-svg"
                                            />
                                            <p class="ml-1 text-dark">{{quiz.total_play}}</p>
                                        </div>
                                        <div class="d-flex mr-4">
                                                <img
                                                    src="@/assets/images/icons/ic_question_grup_grey.svg"
                                                    alt=""
                                                    class="user-svg"
                                                />
                                                <p class="ml-1 text-dark">{{ quiz.total_question }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <img
                                                    src="@/assets/images/icons/ic_clock_grup_grey.svg"
                                                    alt=""
                                                    class="user-svg"
                                                />
                                                <p class="ml-1 text-dark">{{getMinQuiz(quiz.total_question)}} min</p>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mt-3">
                                            <div class="creator-info d-flex align-items-center">
                                                <img
                                                    v-if="userInfo.picture != null && userInfo.picture != ''" 
                                                    :src="userInfo.picture"
                                                    alt=""
                                                    class="ml-2 rounded-circle"
                                                />
                                                <img v-else :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name='+userInfo.name" alt="">
                                                <p class="text-dark ml-1">You,</p>
                                                <p class="ml-1 mb-0 text-truncate" style="font-size: 12px">{{ getDate(quiz.createdAt) }}</p>
                                            </div>
                                            <div class="d-flex align-items-end">
                                                <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start">
                                                <p class="text-dark ml-1 mb-0">{{quiz.score ? quiz.score : '0'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="quizFavorites.length > 0" class="favorite">
                    <div class="container-fluid mt-5">
                        <h3 class="text-left">Favorite</h3>
                        <div class="quiz d-flex mt-3">
                            <div v-for="(quiz, index) in searchMyFavorite" :key="index">
                                <div class="card mr-3" style="width: 18rem;">
                                    <img :src=quiz.images_cover alt="" type="button" v-if="quiz.images_cover != null" class="card-img-top" @click="detailQuiz(quiz._id, quiz.author_quiz)">
                                    <img src="@/assets/images/default-cover.png" type="button" @click="detailQuiz(quiz._id, quiz.author_quiz)" alt="" class="card-img-top" v-else >
                                    <div class="card-body text-left">
                                        <div class="d-flex justify-content-between align-items-start">
                                        <h5 class="card-title text-truncate">{{ quiz.quiz_name }}</h5>
                                        <b-dropdown size="md"  variant="link" toggle-class="text-decoration-none text-dark p-0" no-caret>
                                            <template #button-content>
                                                <b-icon icon="three-dots-vertical"></b-icon>
                                            </template>
                                            <b-dropdown-item @click="share(quiz._id, quiz.autho_quiz)" class="d-flex align-items-center"><img src="@/assets/images/icons/share-icon.svg" alt="" class="user-svg mr-2">Share</b-dropdown-item>
                                            <b-dropdown-item class="d-flex align-items-center" @click="detailQuiz(quiz._id, quiz.author_quiz)"><img src="@/assets/images/icons/duplicate-icon.svg" alt="" class="user-svg mr-2">Duplicate</b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                        <div class="d-flex justify-content-start mb-2">
                                        <div class="d-flex mr-4">
                                            <img
                                                src="@/assets/images/icons/ic_play_grup_grey.svg"
                                                alt=""
                                                class="user-svg"
                                            />
                                            <p class="ml-1 text-dark">{{quiz.total_play}}</p>
                                        </div>
                                        <div class="d-flex mr-4">
                                                <img
                                                    src="@/assets/images/icons/ic_question_grup_grey.svg"
                                                    alt=""
                                                    class="user-svg"
                                                />
                                                <p class="ml-1 text-dark">{{ quiz.total_question }}</p>
                                            </div>
                                            <div class="d-flex">
                                                <img
                                                    src="@/assets/images/icons/ic_clock_grup_grey.svg"
                                                    alt=""
                                                    class="user-svg"
                                                />
                                                <p class="ml-1 text-dark">{{getMinQuiz(quiz.total_question)}} min</p>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mt-3">
                                            <div class="creator-info d-flex align-items-center">
                                                <img
                                                    v-if="quiz.author != null"
                                                    src="@/assets/images/logo/soca-logo.png"
                                                    alt="soca"
                                                    class="ml-2 rounded-circle"
                                                />
                                                <img v-else :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name='+quiz.creator_name" :alt="quiz.creator_name" class="ml-1 rounded-circle">
                                                <p v-if="quiz.creator_name != null" class="text-dark ml-1">{{cutName(quiz.creator_name)}},</p>
                                                <p v-if="quiz.author != null" class="text-dark ml-1">{{quiz.author}},</p>
                                                <p class="ml-1 mb-0 text-truncate" style="font-size: 12px">{{ getDate(quiz.createdAt) }}</p>
                                            </div>
                                            <div class="d-flex align-items-end">
                                                <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start">
                                                <p class="text-dark ml-1 mb-0">{{quiz.score ? quiz.score : '0'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="zero-quiz">
                <img src="@/assets/images/not-found.svg" alt="" class="img-fluid img-sorry">
                <h1 class="mt-3 ml-2">Oops</h1>
                <h3 class="mt-2 font-weight-normal">Kamu belum mempunyai quiz apapun.</h3>
                <h3 class="font-weight-normal">Yuk buat quiz sekarang!</h3>
                <button class="btn btn-primary btn-lg btn-purple mt-4" @click="$bvModal.show('modal-create-content')"><img src="@/assets/images/plus-white.svg" class="mr-2"/>Buat Konten</button>
            </div>
        </div>

        <!-- CONFIRM DELETE MODAL -->
        <b-modal id="delete-konten" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('delete-konten')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/data-none.png" alt="sovia-delete">
                <div class="mt-2">
                    <h5 class="font-weight-bold">Kamu yakin ingin</h5>
                    <h5 class="font-weight-bold">menghapus konten ini?</h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="$bvModal.hide('delete-konten')"><p class="mb-0">Tidak</p></button>
                    <button class="btn btn-primary btn-purple-nb" @click="deleteQuiz()"><p class="mx-2 mb-0">Iya</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { getQuizCreatorAll, getQuizFavorite, deleteQuizCreator } from '@/services/quiz-creator/quiz-creator.service'
import { getQuestionCreator } from '@/services/question-creator/question_creator.service'
import Swal from 'sweetalert2';

export default {
    data(){
        return {
            filter: null,
            loading : false,
            quizCreator : [],
            questionCreator : [],
            quiz_id_delete : null,
            linkShare : '',
            userInfo : [],
            quizFavorites : [],
            useCaseTrendingQuiz : [
                {
                    title : 'Sistem Pemasaran',
                    image : 'Pemasaran & Penjualan.svg',
                },
                {
                    title : 'Simple Present',
                    image : 'Bahasa Inggris.svg',
                },
                {
                    title : 'Bahasa Indonesia',
                    image : 'Bahasa Indonesia.svg',
                },
                {
                    title : 'Ekonomi Dagang',
                    image : 'ekonomi.svg',
                },
                {
                    title : 'Desain Seni Budaya',
                    image : 'seni-budaya.svg',
                },
            ],
        }
    },
    mounted(){
        this.getQuizCreator()
        this.getQuizFavorite()
        let users = localStorage.getItem("users")
        this.userInfo =JSON.parse(users)
    },

    computed: {
        searchMyCollections() {
            if (this.filter) {
                return this.quizCreator.filter((item) => {
                return this.filter
                    .toLowerCase()
                    .split(" ")
                    .every(
                    (v) =>
                        item.quiz_name.toLowerCase().includes(v)
                    );
                });
            } else {
                return this.quizCreator;
            }
        },

        searchMyFavorite() {
            if (this.filter) {
                return this.quizFavorites.filter((item) => {
                return this.filter
                    .toLowerCase()
                    .split(" ")
                    .every(
                    (v) =>
                        item.quiz_name.toLowerCase().includes(v)
                    );
                });
            } else {
                return this.quizFavorites;
            }
        },
    },

    methods: {
        getQuizCreator(){
            this.loading = true;
            getQuizCreatorAll().then((response)=>{
                if(response.status){
                    this.quizCreator = response.data;
                    console.log(this.quizCreator)
                    this.loading = false;
                } else {
                    this.quizCreator = [];
                    this.loading = false;
                }
            }).catch((err)=>{
                this.$vs.loading.close();
                this.loading = false;
                console.log("data err", err)
            })
        },
        editQuizSaya(id){
            console.log(id)
            getQuestionCreator(id)
            .then((response) =>{
                if(response.status && response.status == true){
                    this.questionCreator = response.data
                    this.setToLocal(response.data, id)
                    console.log(this.questionCreator)
                }else {
                    console.log(response)
                }   
            })
                // console.log(this.questionCreator
        },
        setToLocal(data, id){
            localStorage.removeItem("quiz_name_edit")
            localStorage.removeItem("description_edit")
            localStorage.removeItem("images_cover_edit")
            localStorage.removeItem("category_id_edit")
            
            localStorage.setItem('listQuestionCreator', JSON.stringify(data))
            this.$router.push({ name: "Edit-My-Quiz", query: {key:id} });
        },
        
        getDate(item){
            var publish = new Date(item);

            var seconds = Math.floor((new Date() - publish) / 1000);

            var interval = seconds / 31536000;

            if (interval > 1) {
                return Math.floor(interval) + " years ago";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " months ago";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " days ago";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " hours ago";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " minutes ago";
            }
            return Math.floor(seconds) + " seconds ago";
        },
        
        detailQuiz(id, author) {
            localStorage.removeItem("quiz_name_temp")
            localStorage.removeItem("description_temp")
            localStorage.removeItem("images_cover_temp")
            localStorage.removeItem("category_id_temp")
            
            this.$router.push({
                name: "Detail-Quiz",
                query: { key: id, author: author },
            });
        },
        share(id, author){
            this.linkShare ='https://creator.soca.ai/quiz/detail-quiz?key=' + id + '&author=' + author

            this.$copyText(this.linkShare).then(function () {
                alert('Berhasil copy link')
                }, function () {
                alert('Gagal untuk copy link')
                })
            // return this.linkShare
        },
        onCopy: function (e) {
            alert('Berhasil Copy link : ' + e.text)
        },

        onError: function (e) {
            alert('Gagal untuk copy link !')
            console.log(e);
        },

        getMinQuiz(length){
            
            let time = length * 30

            let min = Math.floor(time / 60);

            return min
        }, 

        getQuizFavorite(){
            getQuizFavorite()
            .then((response) => {
                if (response.status && response.status == true){
                    this.quizFavorites = response.data
                    console.log(this.quizFavorites)
                }
            })
        },

        cutName(item){
            if(item != undefined){
                const name = item.split(" ")
                
                return name[0]  
            } else {
                return "-"
            }
        },

        modalDelete(id){
            if(id != null){
                this.quiz_id_delete = null;
            }
            this.quiz_id_delete = id;
            this.$bvModal.show('delete-konten')
        },

        deleteQuiz(){
            deleteQuizCreator(this.quiz_id_delete)
            .then((response) => {
                if (response.status && response.status == true){
                    this.quiz_id_delete = null;
                    this.getQuizCreator()
                    this.$bvModal.hide('delete-konten')
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        customClass:{
                            container: 'pt-5 mt-5'
                        },
                        onOpen: toast => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                        }
                    });
                    Toast.fire({
                        icon: "success",
                        title: "Quiz Berhasil dihapus !"
                    });
                } else {
                    this.quiz_id_delete = null;
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        onOpen: toast => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                        }
                    });
                    Toast.fire({
                        icon: "error",
                        title: "Quiz gagal dihapus !"
                    });
                    this.$bvModal.hide('delete-konten')
                }
            })
        }
    }
}
</script>
<style scoped>
.header .search .form-control{
    padding: 10px 30px;
 }
 
.header label{
    position: relative;
    width: 50%;
}

.card .card-img-top {
    width: 100%;
    height: 12.5rem;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.header label:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
}

.newest .card, .favorite .card{
    border-radius: 15px !important;
}

.newest p, .favorite p{
    color: #8C8C8C;
    margin: 0;
    font-size: 12px;
}

.newest .quiz, .favorite .quiz {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: auto;
}

.newest .quiz::-webkit-scrollbar, .favorite .quiz::-webkit-scrollbar{
    display: none;
}

.creator-info{
  border-radius: 12px;
  height: 25px;
  width: 50%;
  background-color: #F1F1F1;
}
.creator-info img {
  width: 18px;
  height: 18px;
}

.icon-start{
  width: 18px;
  height: 18px;
}

.card-info{
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #F1F1F1;
}

.img-sorry{
  width : 14rem;
  height : 15rem;
}

.zero-quiz h1{
  font-size: 40px;
}

.zero-quiz h3{
  font-size: 28px;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    border-radius: 40px;
}

.btn-purple-nb {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
}

.quiz-saya .modal-content {
    border-radius: 15px !important;
}
</style>